import React, {lazy} from "react";
import { Route, Routes } from "react-router-dom";
import ManagePositions_old from "./components/Pages/ManagePositions_old";
import ManageEvents from "./components/Pages/ManageEvents";
const Landingpage = lazy(() => import("./components/Pages/Landingpage"));
const MultiStepForm = lazy(() => import("./components/Forms/MultiStepForm/MultiStepForm"));
const WorkingAt = lazy(() => import("./components/Pages/WorkingAt"));
const EntryPossibilities = lazy(() => import( "./components/Pages/EntryPossibilities"));
const OpenPositions = lazy(() => import("./components/Pages/OpenPositions"));
const OpenPosition = lazy(() => import("./components/Pages/OpenPosition"));
const PrivacyPolicy = lazy(() => import("./components/Pages/PrivacyPolicy"));
const ManagePositions = lazy(() => import("./components/Pages/ManagePositions"));
const ManageJobApplications = lazy(() => import("./components/Pages/ManageJobApplications"));
const ManageContactMessages = lazy(() => import("./components/Pages/ManageContactMessages"));

export default function AllRoutes() {
    return (
    <Routes>
            <Route path="/:lang/open-position/:id/:platform" element={<OpenPosition/>} />
            <Route path="/:lang/open-position/:id" element={<OpenPosition/>} />
            <Route path="/:lang/open-positions/:prefilter" element={<OpenPositions/>} />
            <Route path="/:lang/open-positions" element={<OpenPositions/>} />
            <Route path="/:lang/working-at" element={<WorkingAt/>} />
            <Route path="/:lang/career-opportunities" element={<EntryPossibilities/>} />
            <Route path="/:lang/application-form/:id" element={<MultiStepForm/>} />
            <Route path="/:lang/privacy-policy" element={<PrivacyPolicy/>} />
            <Route path="/:lang/manage-positions" element={<ManagePositions/>} />
            <Route path="/:lang/manage-jobapplications" element={<ManageJobApplications/>} />
            <Route path="/:lang/manage-contactmessages" element={<ManageContactMessages/>} />
            <Route path="/:lang/manage-events" element={<ManageEvents/>} />
            <Route path="/login" element={<ManagePositions/>} />
            <Route path="/login/" element={<ManagePositions/>} />
            <Route path="/login-old" element={<ManagePositions_old/>} />
            <Route path="/:lang" element={<Landingpage/>} />
            <Route path="/" element={<Landingpage />} />
            <Route path="*" element={<div>404</div>} />
    </Routes>
)
}