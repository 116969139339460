import i18n from "i18next";
//import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationEN from './locales/en/translation.json';
import faqsEN from './locales/en/faqs.json';
import countriesEN from './locales/en/countries.json';
import filecategoriesEN from './locales/en/filecategories.json';
import translationDE from './locales/de/translation.json';
import faqsDE from './locales/de/faqs.json';
import countriesDE from './locales/de/countries.json';
import filecategoriesDE from './locales/de/filecategories.json';
import translationIT from './locales/it/translation.json';

// the translations
const resources = {
    en: {
        translation: translationEN,
        faqs: faqsEN,
        countries: countriesEN,
        filecategories: filecategoriesEN
    },
    de: {
        translation: translationDE,
        faqs: faqsDE,
        countries: countriesDE,
        filecategories: filecategoriesDE
    },
    it: {
        translation: translationIT,
    }
};

i18n
    //.use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        fallbackLng: "en", // use en if detected lng is not available
        //debug: true,
        defaultNS: 'translation',
        supportedLngs: ["en", "de", "it"],
        nonExplicitSupportedLngs: true,
        load: 'languageOnly',
        //keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        },

        react: {
            bindI18n: 'languageChanged',
            bindI18nStore: '',
            transEmptyNodeValue: '',
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
            useSuspense: true,
        }
    });

export default i18n;