import {API} from "aws-amplify";
import notify from "devextreme/ui/notify";
import {getEvent} from "../graphql/queries";
import {createEvent, deleteEvent, updateEvent} from "../graphql/mutations";
import {listEventsForOverview} from "../graphql/custom-queries";

const YEAR_IN_MSEC = 31556926000

export const eventsStore = {
    key: 'id',
    load: async () => {
        let events = [];
        let nt = null;
        do { // this loop is to overcome in a simple manner the limit first and then filter problem
            const {data: {listEvents: {items: page, nextToken}}} = await API.graphql({
                query: listEventsForOverview,
                variables: {
                    limit: 300,
                    nextToken: nt
                },
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            });
            nt = nextToken;
            events = events.concat(page);
        } while (nt !== null)
        return {
            data: events
        };
    },
    insert: (values) => {
        // set ttl to startDate + 1 year
        const ttl = values.startDateTime.getTime() + YEAR_IN_MSEC;
        return API.graphql({
            query: createEvent,
            variables: {input: {
                    ...values,
                    changeStatus: values.changeStatus ? values.changeStatus : 'NO_CHANGE',
                    isFullDay: values.isFullDay ? values.isFullDay : false,
                    ttl: ttl,
                    groupWrite: ['AT', 'DE']
                }
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        }).then(() => notify({message: "Successfully created Event."}, "success", 3000))
            .catch((err) => {
                notify({message: "Error on creating Event."}, "error", 3000);
                console.error({err});
            });
    },
    update: (key, data) => {
        return API.graphql({
            query: getEvent,
            variables:{id:key},
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        })
            .then(({data:{getEvent: old_event}}) => {
                // merge nested attributes
                const items = {
                    ...data,
                    id: key,
                    title: {
                        de: "title" in data && "de" in data?.title ? data?.title?.de : old_event?.title?.de,
                        en: "title" in data && "en" in data?.title ? data?.title?.en : old_event?.title?.en,
                    },
                    description: {
                        de: "description" in data && "de" in data?.description ? data?.description?.de : old_event?.description?.de,
                        en: "description" in data && "en" in data?.description ? data?.description?.en : old_event?.description?.en,
                    },
                    link: {
                        de: "link" in data && "de" in data?.link ? data?.link?.de : old_event?.link?.de,
                        en: "link" in data && "en" in data?.link ? data?.link?.en : old_event?.link?.en
                    }
                };

                return API.graphql({
                    query: updateEvent,
                    variables: {input: items},
                    authMode: 'AMAZON_COGNITO_USER_POOLS'
                }).then(() => notify({message: "Successfully edited Event."}, "success", 3000))
                    .catch((err) => {
                        notify({message: "Error on editing Event."}, "error", 3000);
                        console.error({err});
                    });
            })
            .catch((err) => {
                console.error({err});
                notify(`No Event with Key ${key} found`, "error", 3000);
            });
    },
    remove: (key) => {
        return API.graphql({
                query: deleteEvent,
                variables:{input:{id:key}},
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            }).then(() => notify({message: "Event successfully deleted."}, "success", 3000)
            ).catch((err) => {
                console.error({err});
                notify({message: "Error on deleting Event."}, "error", 3000);
            })
    },
    byKey: (key) => {
        return API.graphql({
            query: getEvent,
            variables:{id:key},
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        })
            .then(({data:{getEvent: result}}) => result)
            .catch((err) => {
                console.error({err});
                notify(`No Event with Key ${key} found`, "error", 3000);
            });
    }
};