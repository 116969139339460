import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MatomoProvider } from "@jonkoops/matomo-tracker-react";
import { matomo_instance } from "./matomo";

ReactDOM.render(
    <MatomoProvider value={matomo_instance}>
        <App />
    </MatomoProvider>,
  document.getElementById('root')
);

// </React.StrictMode>
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
