export function bwidgenerator(lastname, firstname, gender, birthdate) {
    const moment = require('moment');
    // Automatische Bildung der ID: Nachname, Vorname, (je die ersten 2 Buchstaben), Geschlecht (Abkürzung), Geburtsdatum(6stellig ttmmjj)
    const l = handleSpecialSymbols(lastname).slice(0,2);
    const f = handleSpecialSymbols(firstname).slice(0,2);

    let g;
    switch(gender) {
        case "MALE":
            g = "M"; break;
        case "FEMALE":
            g = "W"; break;
        case "DIVERSE":
            g = "D"; break;
        default:
            g = "O";
    }
    const b = Date.parse(birthdate);
    return l+f+g+moment(b).format('DDMMYYYY')+"HP"
};

function handleSpecialSymbols(value) {
    value = value.trim().replace(/[ *',;.?!"]/g, '');
    value = value.replace(/ä/g, 'ae');
    value = value.replace(/Ä/g, 'Ae');
    value = value.replace(/ö/g, 'oe');
    value = value.replace(/Ö/g, 'Oe');
    value = value.replace(/ü/g, 'ue');
    value = value.replace(/Ü/g, 'Ue');
    value = value.replace(/ß/g, 'ss');
    return value;
}

export function idgenerator() {
    const { v4: uuidv4 } = require('uuid');
    return uuidv4();
};