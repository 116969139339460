import React from 'react';
import './NavBar.css';
import {AmplifySignOut} from "@aws-amplify/ui-react";
import {useTranslation} from "react-i18next";
import {generateLink} from "../../settings/linkgenerator";
import { HashLink as Link } from 'react-router-hash-link';

function NavBar(props) {
    const [t, i18n] = useTranslation();

    return (
        <div className={(props.subpage ? "NavBar subpage":"NavBar")}>
            <div className="NavBar-wrapper no-print">
                <ul>
                    {props.items?.map((item) => {
                        return <li  key={item.name} className={(item.separated ? "separated":"" )}>
                            {(item.link ===true ?
                                    <a href={item.path}>{item.name}</a>
                                    :
                                    <Link to={generateLink(item.path, i18n)}>{item.name}</Link>
                            )}
                        </li>
                    })}
                    {/* props.signout ? <li id={"logout"} onClick={() => this.signOut()}><a href={'#'}>Sign Out</a></li>:null */}
                    {props.signout ? <li id={"logout"}><AmplifySignOut/></li> : null}
                </ul>
            </div>
        </div>
    );

    /*async signOut() {
        try {
            await Auth.signOut();
        } catch (error) {
            console.log('Error signing out: ', error);
        }
    }*/
}

/*NavBar.propTypes = {
    subpage: PropTypes.bool,
    items: PropTypes.array,
    signout: PropTypes.bool
}*/

export default NavBar;