import '../../App.css';
import 'devextreme/dist/css/dx.light.css';
import React, {useEffect, useRef, useState} from "react";
import Amplify, {Auth} from 'aws-amplify';
import DataGrid, {
    FilterRow, LoadPanel, Popup, Form, Editing, Button as DataGridButton, Column,
    Sorting, Lookup
} from 'devextreme-react/data-grid';
import awsconfig from '../../aws-exports';
import NavBar from "../Menu/NavBar";
import {ManagementMenuItems_DE, ManagementMenuItems_DEFAULT} from "../../menuItems";
import {Tab, TabbedItem, Item, RequiredRule, PatternRule, TabPanelOptions} from "devextreme-react/form";
import {useTranslation} from "react-i18next";
import MedelAuth from "../Auth/MedelAuth";
//import deMessages from "devextreme/localization/messages/de.json";
import enMessages from "devextreme/localization/messages/en.json";
import { locale, loadMessages } from "devextreme/localization";
import ManagePageHeadline from "../ManagePageHeadline";
import {cognitoSSOConfig} from "../../aws-config";
import Header from "../Header";
import {getClaim} from "../../settings/helpers";
import CustomStore from "devextreme/data/custom_store";
import {eventsStore} from "../../data/eventsStore";
import DateBox from "devextreme-react/date-box";
import CheckBox from "devextreme-react/check-box";

Amplify.configure(awsconfig);
Amplify.configure(cognitoSSOConfig);

const URL_PATTERN = '(https:\/\/|http:\/\/){1}([-%()_.!~*\';\/?:@&=+$,A-Za-z0-9])+';

const renderDateTime = (data, dateField) => {
    const moment = require('moment');
    if (data.isFullDay) {
        return moment(data[dateField]).format("YYYY-MM-DD")
    }
    return moment(data[dateField]).format("YYYY-MM-DD, HH:mm")
}

const renderEditDate = (cell, ref) => {
    const onValueChanged = (e) => cell.setValue(e.value);
    return (
        <DateBox
            ref={ref}
            defaultValue={cell.value}
            onValueChanged={onValueChanged}
            onContentReady={e => {
                const isFullDay = e.component.option("isFullDay") !== undefined ? e.component.option("isFullDay") : cell.data?.isFullDay;
                const dateBoxDisplayFormat = isFullDay ? "MMM dd, yyyy" : "MMM dd, yyyy, HH:mm";
                const dateBoxType = isFullDay  ? "date" : "datetime";
                e.component.option("type", dateBoxType);
                e.component.option("displayFormat", dateBoxDisplayFormat);
            }}
        />
    )
}

const renderIsFullDayCheckbox = (cell, startDateBoxRef, endDateBoxRef) => {
    const onValueChanged = (e) => {
        const dateBoxDisplayFormat = e.value ? "MMM dd, yyyy" : "MMM dd, yyyy, HH:mm";
        const dateBoxType = e.value ? "date" : "datetime";
        startDateBoxRef.current.instance.option("isFullDay", e.value);
        startDateBoxRef.current.instance.option("displayFormat", dateBoxDisplayFormat);
        startDateBoxRef.current.instance.option("type", dateBoxType);
        endDateBoxRef.current.instance.option("isFullDay", e.value);
        endDateBoxRef.current.instance.option("displayFormat", dateBoxDisplayFormat);
        endDateBoxRef.current.instance.option("type", dateBoxType);
        if (e.value && startDateBoxRef.current.instance.option("value")) {
            const startDate = new Date(startDateBoxRef.current.instance.option("value"));
            startDate.setHours(0, 0, 0, 0);
            startDateBoxRef.current.instance.option("value", startDate);
        }
        if (e.value && endDateBoxRef.current.instance.option("value")) {
            const endDate = new Date(endDateBoxRef.current.instance.option("value"));
            endDate.setHours(0, 0, 0, 0);
            endDateBoxRef.current.instance.option("value", endDate);
        }
        cell.setValue(e.value);
    }
    return (
        <CheckBox
            defaultValue={cell.value}
            onValueChanged={onValueChanged}
        />
    )
}

function ManageEvents() {
    const [t, i18n] = useTranslation();
    const [authenticated, setAuthenticated] = useState(false);

    const [form_type, setFormType] = useState('DEFAULT');  // currently allowed values: DEFAULT (for AT etc.), DE

    const startDateBoxRef = useRef();
    const endDateBoxRef = useRef();

    const handleAuthStateChange = (e) => {
        if(e === "signedin") {
            setAuthenticated(true);
        } else {
            setAuthenticated(false);
        }
    }

    useEffect(() => {
        loadMessages(enMessages);
        locale("en");
        Auth.currentCredentials().then((cred) => setAuthenticated(cred.authenticated));
        // generate the group claim permissions that the user will set on creating new Open Positions
        Auth.currentSession()
            .then(cred=> {
                const claim = getClaim(cred.getIdToken().decodePayload());
                const form_type = (claim === "AT") ? "DEFAULT" : claim;
                setFormType(form_type)
            }).catch(err => console.error({err}));
    }, []);

    if (authenticated) {
        return (
                <div className="ManageJobApplications">
                    <Header displayTop={true} mobile={false}/>
                    <NavBar subpage items={form_type === "DE" ? ManagementMenuItems_DE : ManagementMenuItems_DEFAULT} signout={true}/>
                    <ManagePageHeadline title="Manage Events"/>
                    <div className="Content">
                        <DataGrid
                            dataSource={
                                new CustomStore({
                                    ...eventsStore
                                })
                            }
                            keyExpr="id"
                            showBorders={false}
                            defaultFocusedRowIndex={0}
                            allowColumnReordering={true}
                            columnAutoWidth={true}
                            columnHidingEnabled={true}
                            rowAlternationEnabled={true}
                        >
                            <Editing
                                mode="popup"
                                useIcons={true}
                                columnAutoWidth={true}
                                allowUpdating={true}
                                allowAdding={true}
                                allowDeleting={true}>
                                <Popup title="Events" showTitle={true} minHeight={"50vh"}/>
                                <Form colCount={1}
                                      showValidationSummary={true}
                                >
                                    <TabbedItem>
                                        <TabPanelOptions/>
                                        <Tab title={t('manageEvents.settings')} colCount={2}>
                                            <Item dataField="isFullDay"
                                                  colSpan={1}
                                                  editorType="dxCheckBox"
                                            />
                                            <Item dataField="type"
                                                  colSpan={1}>
                                                <RequiredRule message={"Event Type is required"}/>
                                            </Item>
                                            <Item dataField="startDateTime"
                                                  colSpan={1}>
                                                <RequiredRule
                                                    message={"Event Start Date is required"}/>
                                            </Item>
                                            <Item dataField="endDateTime"
                                                  colSpan={1} >
                                                <RequiredRule
                                                    message={"Event End Date is required"}/>
                                            </Item>
                                            <Item dataField="changeStatus"
                                                  colSpan={1}
                                            />
                                            <Item dataField="publicVisible"
                                                  colSpan={1}
                                            >
                                                <RequiredRule message={"Public Visible is required"}/>
                                            </Item>
                                        </Tab>
                                        <Tab title={t('manageEvents.location')} colCount={2}>
                                            <Item dataField="city.de" colSpan={1}/>
                                            <Item dataField="city.en" colSpan={1}/>
                                            <Item dataField="country.de" colSpan={1}/>
                                            <Item dataField="country.en" colSpan={1}/>
                                        </Tab>
                                        <Tab title={t('manageEvents.title')} colCount={2}>
                                            <Item dataField="title.de" colSpan={1} editorType="dxTextArea"
                                                  editorOptions={{height: "150"}}>
                                                <RequiredRule message={"Title (de) is required"}/>
                                            </Item>
                                            <Item dataField="title.en" colSpan={1} editorType="dxTextArea"
                                                  editorOptions={{height: "150"}}>
                                                <RequiredRule message={"Title (en) is required"}/>
                                            </Item>
                                        </Tab>
                                        {/*<Tab title={t('manageEvents.description')}>
                                            <Item dataField="description.en" colSpan={1}/>
                                            <Item dataField="description.en" colSpan={1}/>
                                        </Tab>*/}
                                        <Tab title={t('manageEvents.link')} colCount={2}>
                                            <Item dataField="link_title.de" colSpan={1}/>
                                            <Item dataField="link_title.en" colSpan={1}/>
                                            <Item dataField="link.de" colSpan={1} editorOptions={{
                                                placeholder: t('manageEvents.link-placeholder')
                                            }}>
                                                <PatternRule message={t('manageEvents.link-pattern-rule')}
                                                             pattern={URL_PATTERN}/>
                                            </Item>
                                            <Item dataField="link.en" colSpan={1} editorOptions={{
                                                placeholder: t('manageEvents.link-placeholder')
                                            }}>
                                                <PatternRule message={t('manageEvents.link-pattern-rule')}
                                                             pattern={URL_PATTERN}/>
                                            </Item>
                                        </Tab>
                                    </TabbedItem>
                                </Form>
                            </Editing>
                            <LoadPanel enabled/>
                            <Sorting mode="single" />
                            <FilterRow visible={false} />
                            <Column type="buttons" width={110}>
                                <DataGridButton name="edit"/>
                                <DataGridButton name="delete"/>
                            </Column>
                            <Column dataField="startDateTime"
                                    caption={t('manageEvents.startDate')}
                                    cellRender={({data}) => renderDateTime(data, 'startDateTime')}
                                    editCellRender={(e) => renderEditDate(e, startDateBoxRef)}
                                    defaultSortIndex={0}
                                    defaultSortOrder="desc"
                            />
                            <Column dataField="endDateTime"
                                    caption={t('manageEvents.endDate')}
                                    cellRender={({data}) => renderDateTime(data, 'endDateTime')}
                                    editCellRender={(e) => renderEditDate(e, endDateBoxRef)}
                            />
                            <Column dataField="isFullDay"
                                    caption={t('manageEvents.isFullDay')}
                                    dataType="boolean"
                                    editCellRender={(e) => renderIsFullDayCheckbox(e, startDateBoxRef, endDateBoxRef)}
                            />
                            <Column dataField="type"
                                    caption={t('manageEvents.type')}>
                                <Lookup dataSource={t('EventType', {returnObjects: true})} displayExpr="name" valueExpr="value" />
                            </Column>
                            <Column dataField="publicVisible"
                                    caption={t('manageEvents.publicVisible')}>
                                <Lookup dataSource={t('PublicVisible', {returnObjects: true})} displayExpr="name" valueExpr="value" />
                            </Column>
                            <Column dataField="changeStatus"
                                    caption={t('manageEvents.changeStatus')}>
                                <Lookup dataSource={t('EventChangeStatus', {returnObjects: true})} displayExpr="name" valueExpr="value" />
                            </Column>
                            <Column dataField="city.de"
                                    caption={t('manageEvents.city-de')}
                            />
                            <Column dataField="country.de"
                                    caption={t('manageEvents.country-de')}
                            />
                            <Column dataField="city.en"
                                    caption={t('manageEvents.city-en')}
                            />
                            <Column dataField="country.en"
                                    caption={t('manageEvents.country-en')}
                            />
                            <Column dataField="title.de"
                                    caption={t('manageEvents.title-de')}
                            />
                            <Column dataField="title.en"
                                    caption={t('manageEvents.title-en')}
                            />
                            {/*<Column dataField="description.de"
                                    caption={t('manageEvents.description-de')}
                            />
                            <Column dataField="description.en"
                                    caption={t('manageEvents.description-en')}
                            />*/}
                            <Column dataField="link.de"
                                    caption={t('manageEvents.link-de')}
                            />
                            <Column dataField="link.en"
                                    caption={t('manageEvents.link-en')}
                            />
                            <Column dataField="link_title.de"
                                    caption={t('manageEvents.link_title-de')}
                            />
                            <Column dataField="link_title.en"
                                    caption={t('manageEvents.link_title-en')}
                            />
                        </DataGrid>
                    </div>
                </div>
            );
    } else {
        return (
            <MedelAuth handleAuthStateChange={handleAuthStateChange}/>
        );
    }
}

export default ManageEvents;
