export const REGEXP_OnlyLetters = RegExp("^[^0-9]+$")
export const REGEXP_OnlyDigits = RegExp("^[0-9]+$")
export const REGEXP_AlphaNumeric = RegExp("^[A-Za-z0-9 -]+$")
export const REGEXP_Phone = RegExp("^[+]?[0-9 /]{3,20}$")
export const REGEXP_Email = RegExp("^[A-Za-z0-9.!#$%&*+-=?^_{|}~]+[@]{1}[A-Za-z0-9.!#$%&*+-=?^_{|}~]+[.]{1}[A-Za-z]{2,3}$")

export function validateName(input) {
    return REGEXP_OnlyLetters.test(input)
}

export function validateFirstAndSurName(input) {
    const value = input.trim().replace(/[ *',;.?!"]/g, '');
    // length at least two chars and only letters
    return value.length>1 && REGEXP_OnlyLetters.test(input)
}

export function validateEmail(input) {
    return REGEXP_Email.test(input);
}

export function validateZipcode(input) {
    return REGEXP_AlphaNumeric.test(input);
}

export function validatePhoneNumber(input) {
    return REGEXP_Phone.test(input);
}

export function isValidURL(url) {
    try { return Boolean(new URL(url)); }
    catch(e){ return false; }
};

export function validateNotEmpty(value) {
    if (value.trim() === '') {
        return false
    }
    return true;
}

export function validateDate(value) {
    const moment = require('moment');
    if (value === '' || value === undefined || !(value instanceof Date)) {
        return false;
    }
    try {
        let date = moment(value);
        if (date.isValid()) {
            date.format('YYYY-MM-DD');
        } else {
            return false;
        }
    } catch (err) {
        return false;
    }
    return true;
}