import {Button as DevexButton} from "devextreme-react/button";
import '../App.css';

function Button(props) {
    const classname = props.centered ? "Button center" : "Button";
    return (
        <div className={classname + ' ' + props.className}>
            <DevexButton stylingMode="contained"
                         type={(props.type ? props.type : "normal")}
                         useSubmitBehavior={(props.useSubmitBehavior ? props.useSubmitBehavior : false)}
                         activeStateEnabled={false}
                         hoverStateEnabled={false}
                         disabled={props.disabled}
                         text={props.text ? props.text : null}
                         icon={props.icon ? props.icon : null}
                         onClick={props.onClick}/>
        </div>
    );
}

/*Button.propTypes = {
    text: PropTypes.string.isRequired,
    type: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    centered: PropTypes.bool,
    useSubmitBehavior: PropTypes.bool,
}*/

export default Button;