/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBoxFolder = /* GraphQL */ `
  query GetBoxFolder($prefix: String!) {
    getBoxFolder(prefix: $prefix) {
      prefix
      code
      boxfolderid_pdf
      boxfolderid_original
      jobareaDE
      jobareaEN
      dpwusergroup
      dpwcode
      dpwdescription
      kst
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listBoxFolders = /* GraphQL */ `
  query ListBoxFolders(
    $prefix: String
    $filter: ModelBoxFolderFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBoxFolders(
      prefix: $prefix
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        prefix
        code
        boxfolderid_pdf
        boxfolderid_original
        jobareaDE
        jobareaEN
        dpwusergroup
        dpwcode
        dpwdescription
        kst
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOpenPosition = /* GraphQL */ `
  query GetOpenPosition($id: ID!) {
    getOpenPosition(id: $id) {
      id
      medel_id
      title
      area
      location
      city
      countrycode
      level
      extenttype
      extenttime
      employmenttype
      employmenttime
      activity
      profile
      headerimageID
      salarysupplement
      entrydate
      temporary_position
      contactperson
      contactpersonRefID
      bwusergroup
      objnr
      internal_recommendation_flag
      online_application_flag
      free_text1
      free_text2
      header_tasks
      header_profile
      header_benefits
      header_salarysupplement
      header_entrydate
      header_temporary_position
      header_contactperson
      link
      language
      show_on
      state
      ttl
      groupWrite
      createdAt
      updatedAt
      priority
      benefits {
        nextToken
        __typename
      }
      headerimage {
        id
        filepath
        groupWrite
        createdAt
        updatedAt
        __typename
      }
      contactpersonRef {
        id
        firstName
        lastName
        imgPath
        email
        text
        groupWrite
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listOpenPositions = /* GraphQL */ `
  query ListOpenPositions(
    $filter: ModelOpenPositionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOpenPositions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        medel_id
        title
        area
        location
        city
        countrycode
        level
        extenttype
        extenttime
        employmenttype
        employmenttime
        activity
        profile
        headerimageID
        salarysupplement
        entrydate
        temporary_position
        contactperson
        contactpersonRefID
        bwusergroup
        objnr
        internal_recommendation_flag
        online_application_flag
        free_text1
        free_text2
        header_tasks
        header_profile
        header_benefits
        header_salarysupplement
        header_entrydate
        header_temporary_position
        header_contactperson
        link
        language
        show_on
        state
        ttl
        groupWrite
        createdAt
        updatedAt
        priority
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const openpositionsByStatus = /* GraphQL */ `
  query OpenpositionsByStatus(
    $state: State
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOpenPositionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    openpositionsByStatus(
      state: $state
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        medel_id
        title
        area
        location
        city
        countrycode
        level
        extenttype
        extenttime
        employmenttype
        employmenttime
        activity
        profile
        headerimageID
        salarysupplement
        entrydate
        temporary_position
        contactperson
        contactpersonRefID
        bwusergroup
        objnr
        internal_recommendation_flag
        online_application_flag
        free_text1
        free_text2
        header_tasks
        header_profile
        header_benefits
        header_salarysupplement
        header_entrydate
        header_temporary_position
        header_contactperson
        link
        language
        show_on
        state
        ttl
        groupWrite
        createdAt
        updatedAt
        priority
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const openpositionsByPriority = /* GraphQL */ `
  query OpenpositionsByPriority(
    $priority: Priority
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOpenPositionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    openpositionsByPriority(
      priority: $priority
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        medel_id
        title
        area
        location
        city
        countrycode
        level
        extenttype
        extenttime
        employmenttype
        employmenttime
        activity
        profile
        headerimageID
        salarysupplement
        entrydate
        temporary_position
        contactperson
        contactpersonRefID
        bwusergroup
        objnr
        internal_recommendation_flag
        online_application_flag
        free_text1
        free_text2
        header_tasks
        header_profile
        header_benefits
        header_salarysupplement
        header_entrydate
        header_temporary_position
        header_contactperson
        link
        language
        show_on
        state
        ttl
        groupWrite
        createdAt
        updatedAt
        priority
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const openpositionByMedelID = /* GraphQL */ `
  query OpenpositionByMedelID(
    $medel_id: ID
    $show_on: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOpenPositionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    openpositionByMedelID(
      medel_id: $medel_id
      show_on: $show_on
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        medel_id
        title
        area
        location
        city
        countrycode
        level
        extenttype
        extenttime
        employmenttype
        employmenttime
        activity
        profile
        headerimageID
        salarysupplement
        entrydate
        temporary_position
        contactperson
        contactpersonRefID
        bwusergroup
        objnr
        internal_recommendation_flag
        online_application_flag
        free_text1
        free_text2
        header_tasks
        header_profile
        header_benefits
        header_salarysupplement
        header_entrydate
        header_temporary_position
        header_contactperson
        link
        language
        show_on
        state
        ttl
        groupWrite
        createdAt
        updatedAt
        priority
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOpenPositionBenefit = /* GraphQL */ `
  query GetOpenPositionBenefit($id: ID!) {
    getOpenPositionBenefit(id: $id) {
      id
      openpositionID
      benefitID
      groupWrite
      createdAt
      updatedAt
      openposition {
        id
        medel_id
        title
        area
        location
        city
        countrycode
        level
        extenttype
        extenttime
        employmenttype
        employmenttime
        activity
        profile
        headerimageID
        salarysupplement
        entrydate
        temporary_position
        contactperson
        contactpersonRefID
        bwusergroup
        objnr
        internal_recommendation_flag
        online_application_flag
        free_text1
        free_text2
        header_tasks
        header_profile
        header_benefits
        header_salarysupplement
        header_entrydate
        header_temporary_position
        header_contactperson
        link
        language
        show_on
        state
        ttl
        groupWrite
        createdAt
        updatedAt
        priority
        __typename
      }
      benefit {
        id
        description_de
        description_en
        icon
        groupWrite
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listOpenPositionBenefits = /* GraphQL */ `
  query ListOpenPositionBenefits(
    $filter: ModelOpenPositionBenefitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOpenPositionBenefits(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        openpositionID
        benefitID
        groupWrite
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const openPositionBenefitByOpenPosition = /* GraphQL */ `
  query OpenPositionBenefitByOpenPosition(
    $openpositionID: ID
    $benefitID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOpenPositionBenefitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    openPositionBenefitByOpenPosition(
      openpositionID: $openpositionID
      benefitID: $benefitID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        openpositionID
        benefitID
        groupWrite
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBenefit = /* GraphQL */ `
  query GetBenefit($id: ID!) {
    getBenefit(id: $id) {
      id
      description_de
      description_en
      icon
      groupWrite
      createdAt
      updatedAt
      openpositions {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listBenefits = /* GraphQL */ `
  query ListBenefits(
    $filter: ModelBenefitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBenefits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        description_de
        description_en
        icon
        groupWrite
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getHeaderImage = /* GraphQL */ `
  query GetHeaderImage($id: ID!) {
    getHeaderImage(id: $id) {
      id
      filepath
      groupWrite
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listHeaderImages = /* GraphQL */ `
  query ListHeaderImages(
    $filter: ModelHeaderImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHeaderImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        filepath
        groupWrite
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getContactPerson = /* GraphQL */ `
  query GetContactPerson($id: ID!) {
    getContactPerson(id: $id) {
      id
      firstName
      lastName
      imgPath
      email
      text
      groupWrite
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listContactPeople = /* GraphQL */ `
  query ListContactPeople(
    $filter: ModelContactPersonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactPeople(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        imgPath
        email
        text
        groupWrite
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getContactMessage = /* GraphQL */ `
  query GetContactMessage($id: ID!) {
    getContactMessage(id: $id) {
      id
      firstname
      lastname
      email
      phone
      comment
      newsletter
      createdAt
      ttl
      updatedAt
      __typename
    }
  }
`;
export const listContactMessages = /* GraphQL */ `
  query ListContactMessages(
    $filter: ModelContactMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstname
        lastname
        email
        phone
        comment
        newsletter
        createdAt
        ttl
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getJobApplication = /* GraphQL */ `
  query GetJobApplication($id: ID!) {
    getJobApplication(id: $id) {
      id
      medel_jobApplicationID
      medel_id
      openposition_title
      openpositionID
      gender
      firstname
      lastname
      birthdate
      nationality
      phone
      email
      address
      zipcode
      citystateprovince
      country
      vacancy
      recommendation_person
      hoursperweek
      ja_extenttype
      startdate
      language
      curemplstatus
      exportflag
      createdAt
      updatedAt
      openposition {
        id
        medel_id
        title
        area
        location
        city
        countrycode
        level
        extenttype
        extenttime
        employmenttype
        employmenttime
        activity
        profile
        headerimageID
        salarysupplement
        entrydate
        temporary_position
        contactperson
        contactpersonRefID
        bwusergroup
        objnr
        internal_recommendation_flag
        online_application_flag
        free_text1
        free_text2
        header_tasks
        header_profile
        header_benefits
        header_salarysupplement
        header_entrydate
        header_temporary_position
        header_contactperson
        link
        language
        show_on
        state
        ttl
        groupWrite
        createdAt
        updatedAt
        priority
        __typename
      }
      documents {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listJobApplications = /* GraphQL */ `
  query ListJobApplications(
    $filter: ModelJobApplicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobApplications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        medel_jobApplicationID
        medel_id
        openposition_title
        openpositionID
        gender
        firstname
        lastname
        birthdate
        nationality
        phone
        email
        address
        zipcode
        citystateprovince
        country
        vacancy
        recommendation_person
        hoursperweek
        ja_extenttype
        startdate
        language
        curemplstatus
        exportflag
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const jobApplicationByExportFlag = /* GraphQL */ `
  query JobApplicationByExportFlag(
    $exportflag: Export
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJobApplicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobApplicationByExportFlag(
      exportflag: $exportflag
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        medel_jobApplicationID
        medel_id
        openposition_title
        openpositionID
        gender
        firstname
        lastname
        birthdate
        nationality
        phone
        email
        address
        zipcode
        citystateprovince
        country
        vacancy
        recommendation_person
        hoursperweek
        ja_extenttype
        startdate
        language
        curemplstatus
        exportflag
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const jobApplicationByMedelID = /* GraphQL */ `
  query JobApplicationByMedelID(
    $medel_id: ID
    $medel_jobApplicationID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJobApplicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobApplicationByMedelID(
      medel_id: $medel_id
      medel_jobApplicationID: $medel_jobApplicationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        medel_jobApplicationID
        medel_id
        openposition_title
        openpositionID
        gender
        firstname
        lastname
        birthdate
        nationality
        phone
        email
        address
        zipcode
        citystateprovince
        country
        vacancy
        recommendation_person
        hoursperweek
        ja_extenttype
        startdate
        language
        curemplstatus
        exportflag
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const jobApplicationByMedeljobApplicationID = /* GraphQL */ `
  query JobApplicationByMedeljobApplicationID(
    $medel_jobApplicationID: ID
    $medel_id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJobApplicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobApplicationByMedeljobApplicationID(
      medel_jobApplicationID: $medel_jobApplicationID
      medel_id: $medel_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        medel_jobApplicationID
        medel_id
        openposition_title
        openpositionID
        gender
        firstname
        lastname
        birthdate
        nationality
        phone
        email
        address
        zipcode
        citystateprovince
        country
        vacancy
        recommendation_person
        hoursperweek
        ja_extenttype
        startdate
        language
        curemplstatus
        exportflag
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getApplicationDocument = /* GraphQL */ `
  query GetApplicationDocument($id: ID!) {
    getApplicationDocument(id: $id) {
      id
      medel_jobApplicationID
      medel_id
      name
      type
      extension
      path
      link
      original
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listApplicationDocuments = /* GraphQL */ `
  query ListApplicationDocuments(
    $filter: ModelApplicationDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApplicationDocuments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        medel_jobApplicationID
        medel_id
        name
        type
        extension
        path
        link
        original
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const applicationDocumentByJobApplicationID = /* GraphQL */ `
  query ApplicationDocumentByJobApplicationID(
    $medel_jobApplicationID: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelApplicationDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    applicationDocumentByJobApplicationID(
      medel_jobApplicationID: $medel_jobApplicationID
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        medel_jobApplicationID
        medel_id
        name
        type
        extension
        path
        link
        original
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const applicationDocumentByMedelID = /* GraphQL */ `
  query ApplicationDocumentByMedelID(
    $medel_id: ID
    $medel_jobApplicationID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelApplicationDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    applicationDocumentByMedelID(
      medel_id: $medel_id
      medel_jobApplicationID: $medel_jobApplicationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        medel_jobApplicationID
        medel_id
        name
        type
        extension
        path
        link
        original
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const applicationDocumentByName = /* GraphQL */ `
  query ApplicationDocumentByName(
    $name: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelApplicationDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    applicationDocumentByName(
      name: $name
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        medel_jobApplicationID
        medel_id
        name
        type
        extension
        path
        link
        original
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      title {
        de
        en
        __typename
      }
      description {
        de
        en
        __typename
      }
      link {
        de
        en
        __typename
      }
      link_title {
        de
        en
        __typename
      }
      type
      changeStatus
      publicVisible
      startDateTime
      endDateTime
      city {
        de
        en
        __typename
      }
      country {
        de
        en
        __typename
      }
      isFullDay
      ttl
      groupWrite
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        changeStatus
        publicVisible
        startDateTime
        endDateTime
        isFullDay
        ttl
        groupWrite
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const eventsByPublishedAndStartDateTime = /* GraphQL */ `
  query EventsByPublishedAndStartDateTime(
    $publicVisible: PublicVisible
    $startDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventsByPublishedAndStartDateTime(
      publicVisible: $publicVisible
      startDateTime: $startDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        changeStatus
        publicVisible
        startDateTime
        endDateTime
        isFullDay
        ttl
        groupWrite
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const eventsByPublishedAndEndDateTime = /* GraphQL */ `
  query EventsByPublishedAndEndDateTime(
    $publicVisible: PublicVisible
    $endDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventsByPublishedAndEndDateTime(
      publicVisible: $publicVisible
      endDateTime: $endDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        changeStatus
        publicVisible
        startDateTime
        endDateTime
        isFullDay
        ttl
        groupWrite
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const eventsByTypeAndStartDateTime = /* GraphQL */ `
  query EventsByTypeAndStartDateTime(
    $type: EventType
    $startDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventsByTypeAndStartDateTime(
      type: $type
      startDateTime: $startDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        changeStatus
        publicVisible
        startDateTime
        endDateTime
        isFullDay
        ttl
        groupWrite
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
