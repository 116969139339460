
const groupClaim = "cognito:groups"; // this corresponds to the groupClaim in the schema.graphql; adaptable for tests

/**
 * extracts the claim (AT or DE) from the Session Payload
 * @param payload {Object}
 * @returns {"AT" | "DE" | null}
 */
export function getClaim(payload) {
    if (payload) {
        let claim = payload[groupClaim];
        if (claim.includes("AT")) {
            return "AT";
        } else if (claim.includes("DE")) {
            return "DE";
        }
    }
    return null;
}