/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createBoxFolder = /* GraphQL */ `
  mutation CreateBoxFolder(
    $input: CreateBoxFolderInput!
    $condition: ModelBoxFolderConditionInput
  ) {
    createBoxFolder(input: $input, condition: $condition) {
      prefix
      code
      boxfolderid_pdf
      boxfolderid_original
      jobareaDE
      jobareaEN
      dpwusergroup
      dpwcode
      dpwdescription
      kst
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateBoxFolder = /* GraphQL */ `
  mutation UpdateBoxFolder(
    $input: UpdateBoxFolderInput!
    $condition: ModelBoxFolderConditionInput
  ) {
    updateBoxFolder(input: $input, condition: $condition) {
      prefix
      code
      boxfolderid_pdf
      boxfolderid_original
      jobareaDE
      jobareaEN
      dpwusergroup
      dpwcode
      dpwdescription
      kst
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteBoxFolder = /* GraphQL */ `
  mutation DeleteBoxFolder(
    $input: DeleteBoxFolderInput!
    $condition: ModelBoxFolderConditionInput
  ) {
    deleteBoxFolder(input: $input, condition: $condition) {
      prefix
      code
      boxfolderid_pdf
      boxfolderid_original
      jobareaDE
      jobareaEN
      dpwusergroup
      dpwcode
      dpwdescription
      kst
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOpenPosition = /* GraphQL */ `
  mutation CreateOpenPosition(
    $input: CreateOpenPositionInput!
    $condition: ModelOpenPositionConditionInput
  ) {
    createOpenPosition(input: $input, condition: $condition) {
      id
      medel_id
      title
      area
      location
      city
      countrycode
      level
      extenttype
      extenttime
      employmenttype
      employmenttime
      activity
      profile
      headerimageID
      salarysupplement
      entrydate
      temporary_position
      contactperson
      contactpersonRefID
      bwusergroup
      objnr
      internal_recommendation_flag
      online_application_flag
      free_text1
      free_text2
      header_tasks
      header_profile
      header_benefits
      header_salarysupplement
      header_entrydate
      header_temporary_position
      header_contactperson
      link
      language
      show_on
      state
      ttl
      groupWrite
      createdAt
      updatedAt
      priority
      benefits {
        nextToken
        __typename
      }
      headerimage {
        id
        filepath
        groupWrite
        createdAt
        updatedAt
        __typename
      }
      contactpersonRef {
        id
        firstName
        lastName
        imgPath
        email
        text
        groupWrite
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateOpenPosition = /* GraphQL */ `
  mutation UpdateOpenPosition(
    $input: UpdateOpenPositionInput!
    $condition: ModelOpenPositionConditionInput
  ) {
    updateOpenPosition(input: $input, condition: $condition) {
      id
      medel_id
      title
      area
      location
      city
      countrycode
      level
      extenttype
      extenttime
      employmenttype
      employmenttime
      activity
      profile
      headerimageID
      salarysupplement
      entrydate
      temporary_position
      contactperson
      contactpersonRefID
      bwusergroup
      objnr
      internal_recommendation_flag
      online_application_flag
      free_text1
      free_text2
      header_tasks
      header_profile
      header_benefits
      header_salarysupplement
      header_entrydate
      header_temporary_position
      header_contactperson
      link
      language
      show_on
      state
      ttl
      groupWrite
      createdAt
      updatedAt
      priority
      benefits {
        nextToken
        __typename
      }
      headerimage {
        id
        filepath
        groupWrite
        createdAt
        updatedAt
        __typename
      }
      contactpersonRef {
        id
        firstName
        lastName
        imgPath
        email
        text
        groupWrite
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteOpenPosition = /* GraphQL */ `
  mutation DeleteOpenPosition(
    $input: DeleteOpenPositionInput!
    $condition: ModelOpenPositionConditionInput
  ) {
    deleteOpenPosition(input: $input, condition: $condition) {
      id
      medel_id
      title
      area
      location
      city
      countrycode
      level
      extenttype
      extenttime
      employmenttype
      employmenttime
      activity
      profile
      headerimageID
      salarysupplement
      entrydate
      temporary_position
      contactperson
      contactpersonRefID
      bwusergroup
      objnr
      internal_recommendation_flag
      online_application_flag
      free_text1
      free_text2
      header_tasks
      header_profile
      header_benefits
      header_salarysupplement
      header_entrydate
      header_temporary_position
      header_contactperson
      link
      language
      show_on
      state
      ttl
      groupWrite
      createdAt
      updatedAt
      priority
      benefits {
        nextToken
        __typename
      }
      headerimage {
        id
        filepath
        groupWrite
        createdAt
        updatedAt
        __typename
      }
      contactpersonRef {
        id
        firstName
        lastName
        imgPath
        email
        text
        groupWrite
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createOpenPositionBenefit = /* GraphQL */ `
  mutation CreateOpenPositionBenefit(
    $input: CreateOpenPositionBenefitInput!
    $condition: ModelOpenPositionBenefitConditionInput
  ) {
    createOpenPositionBenefit(input: $input, condition: $condition) {
      id
      openpositionID
      benefitID
      groupWrite
      createdAt
      updatedAt
      openposition {
        id
        medel_id
        title
        area
        location
        city
        countrycode
        level
        extenttype
        extenttime
        employmenttype
        employmenttime
        activity
        profile
        headerimageID
        salarysupplement
        entrydate
        temporary_position
        contactperson
        contactpersonRefID
        bwusergroup
        objnr
        internal_recommendation_flag
        online_application_flag
        free_text1
        free_text2
        header_tasks
        header_profile
        header_benefits
        header_salarysupplement
        header_entrydate
        header_temporary_position
        header_contactperson
        link
        language
        show_on
        state
        ttl
        groupWrite
        createdAt
        updatedAt
        priority
        __typename
      }
      benefit {
        id
        description_de
        description_en
        icon
        groupWrite
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateOpenPositionBenefit = /* GraphQL */ `
  mutation UpdateOpenPositionBenefit(
    $input: UpdateOpenPositionBenefitInput!
    $condition: ModelOpenPositionBenefitConditionInput
  ) {
    updateOpenPositionBenefit(input: $input, condition: $condition) {
      id
      openpositionID
      benefitID
      groupWrite
      createdAt
      updatedAt
      openposition {
        id
        medel_id
        title
        area
        location
        city
        countrycode
        level
        extenttype
        extenttime
        employmenttype
        employmenttime
        activity
        profile
        headerimageID
        salarysupplement
        entrydate
        temporary_position
        contactperson
        contactpersonRefID
        bwusergroup
        objnr
        internal_recommendation_flag
        online_application_flag
        free_text1
        free_text2
        header_tasks
        header_profile
        header_benefits
        header_salarysupplement
        header_entrydate
        header_temporary_position
        header_contactperson
        link
        language
        show_on
        state
        ttl
        groupWrite
        createdAt
        updatedAt
        priority
        __typename
      }
      benefit {
        id
        description_de
        description_en
        icon
        groupWrite
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteOpenPositionBenefit = /* GraphQL */ `
  mutation DeleteOpenPositionBenefit(
    $input: DeleteOpenPositionBenefitInput!
    $condition: ModelOpenPositionBenefitConditionInput
  ) {
    deleteOpenPositionBenefit(input: $input, condition: $condition) {
      id
      openpositionID
      benefitID
      groupWrite
      createdAt
      updatedAt
      openposition {
        id
        medel_id
        title
        area
        location
        city
        countrycode
        level
        extenttype
        extenttime
        employmenttype
        employmenttime
        activity
        profile
        headerimageID
        salarysupplement
        entrydate
        temporary_position
        contactperson
        contactpersonRefID
        bwusergroup
        objnr
        internal_recommendation_flag
        online_application_flag
        free_text1
        free_text2
        header_tasks
        header_profile
        header_benefits
        header_salarysupplement
        header_entrydate
        header_temporary_position
        header_contactperson
        link
        language
        show_on
        state
        ttl
        groupWrite
        createdAt
        updatedAt
        priority
        __typename
      }
      benefit {
        id
        description_de
        description_en
        icon
        groupWrite
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createBenefit = /* GraphQL */ `
  mutation CreateBenefit(
    $input: CreateBenefitInput!
    $condition: ModelBenefitConditionInput
  ) {
    createBenefit(input: $input, condition: $condition) {
      id
      description_de
      description_en
      icon
      groupWrite
      createdAt
      updatedAt
      openpositions {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateBenefit = /* GraphQL */ `
  mutation UpdateBenefit(
    $input: UpdateBenefitInput!
    $condition: ModelBenefitConditionInput
  ) {
    updateBenefit(input: $input, condition: $condition) {
      id
      description_de
      description_en
      icon
      groupWrite
      createdAt
      updatedAt
      openpositions {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteBenefit = /* GraphQL */ `
  mutation DeleteBenefit(
    $input: DeleteBenefitInput!
    $condition: ModelBenefitConditionInput
  ) {
    deleteBenefit(input: $input, condition: $condition) {
      id
      description_de
      description_en
      icon
      groupWrite
      createdAt
      updatedAt
      openpositions {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createHeaderImage = /* GraphQL */ `
  mutation CreateHeaderImage(
    $input: CreateHeaderImageInput!
    $condition: ModelHeaderImageConditionInput
  ) {
    createHeaderImage(input: $input, condition: $condition) {
      id
      filepath
      groupWrite
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateHeaderImage = /* GraphQL */ `
  mutation UpdateHeaderImage(
    $input: UpdateHeaderImageInput!
    $condition: ModelHeaderImageConditionInput
  ) {
    updateHeaderImage(input: $input, condition: $condition) {
      id
      filepath
      groupWrite
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteHeaderImage = /* GraphQL */ `
  mutation DeleteHeaderImage(
    $input: DeleteHeaderImageInput!
    $condition: ModelHeaderImageConditionInput
  ) {
    deleteHeaderImage(input: $input, condition: $condition) {
      id
      filepath
      groupWrite
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createContactPerson = /* GraphQL */ `
  mutation CreateContactPerson(
    $input: CreateContactPersonInput!
    $condition: ModelContactPersonConditionInput
  ) {
    createContactPerson(input: $input, condition: $condition) {
      id
      firstName
      lastName
      imgPath
      email
      text
      groupWrite
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateContactPerson = /* GraphQL */ `
  mutation UpdateContactPerson(
    $input: UpdateContactPersonInput!
    $condition: ModelContactPersonConditionInput
  ) {
    updateContactPerson(input: $input, condition: $condition) {
      id
      firstName
      lastName
      imgPath
      email
      text
      groupWrite
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteContactPerson = /* GraphQL */ `
  mutation DeleteContactPerson(
    $input: DeleteContactPersonInput!
    $condition: ModelContactPersonConditionInput
  ) {
    deleteContactPerson(input: $input, condition: $condition) {
      id
      firstName
      lastName
      imgPath
      email
      text
      groupWrite
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createContactMessage = /* GraphQL */ `
  mutation CreateContactMessage(
    $input: CreateContactMessageInput!
    $condition: ModelContactMessageConditionInput
  ) {
    createContactMessage(input: $input, condition: $condition) {
      id
      firstname
      lastname
      email
      phone
      comment
      newsletter
      createdAt
      ttl
      updatedAt
      __typename
    }
  }
`;
export const updateContactMessage = /* GraphQL */ `
  mutation UpdateContactMessage(
    $input: UpdateContactMessageInput!
    $condition: ModelContactMessageConditionInput
  ) {
    updateContactMessage(input: $input, condition: $condition) {
      id
      firstname
      lastname
      email
      phone
      comment
      newsletter
      createdAt
      ttl
      updatedAt
      __typename
    }
  }
`;
export const deleteContactMessage = /* GraphQL */ `
  mutation DeleteContactMessage(
    $input: DeleteContactMessageInput!
    $condition: ModelContactMessageConditionInput
  ) {
    deleteContactMessage(input: $input, condition: $condition) {
      id
      firstname
      lastname
      email
      phone
      comment
      newsletter
      createdAt
      ttl
      updatedAt
      __typename
    }
  }
`;
export const createJobApplication = /* GraphQL */ `
  mutation CreateJobApplication(
    $input: CreateJobApplicationInput!
    $condition: ModelJobApplicationConditionInput
  ) {
    createJobApplication(input: $input, condition: $condition) {
      id
      medel_jobApplicationID
      medel_id
      openposition_title
      openpositionID
      gender
      firstname
      lastname
      birthdate
      nationality
      phone
      email
      address
      zipcode
      citystateprovince
      country
      vacancy
      recommendation_person
      hoursperweek
      ja_extenttype
      startdate
      language
      curemplstatus
      exportflag
      createdAt
      updatedAt
      openposition {
        id
        medel_id
        title
        area
        location
        city
        countrycode
        level
        extenttype
        extenttime
        employmenttype
        employmenttime
        activity
        profile
        headerimageID
        salarysupplement
        entrydate
        temporary_position
        contactperson
        contactpersonRefID
        bwusergroup
        objnr
        internal_recommendation_flag
        online_application_flag
        free_text1
        free_text2
        header_tasks
        header_profile
        header_benefits
        header_salarysupplement
        header_entrydate
        header_temporary_position
        header_contactperson
        link
        language
        show_on
        state
        ttl
        groupWrite
        createdAt
        updatedAt
        priority
        __typename
      }
      documents {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateJobApplication = /* GraphQL */ `
  mutation UpdateJobApplication(
    $input: UpdateJobApplicationInput!
    $condition: ModelJobApplicationConditionInput
  ) {
    updateJobApplication(input: $input, condition: $condition) {
      id
      medel_jobApplicationID
      medel_id
      openposition_title
      openpositionID
      gender
      firstname
      lastname
      birthdate
      nationality
      phone
      email
      address
      zipcode
      citystateprovince
      country
      vacancy
      recommendation_person
      hoursperweek
      ja_extenttype
      startdate
      language
      curemplstatus
      exportflag
      createdAt
      updatedAt
      openposition {
        id
        medel_id
        title
        area
        location
        city
        countrycode
        level
        extenttype
        extenttime
        employmenttype
        employmenttime
        activity
        profile
        headerimageID
        salarysupplement
        entrydate
        temporary_position
        contactperson
        contactpersonRefID
        bwusergroup
        objnr
        internal_recommendation_flag
        online_application_flag
        free_text1
        free_text2
        header_tasks
        header_profile
        header_benefits
        header_salarysupplement
        header_entrydate
        header_temporary_position
        header_contactperson
        link
        language
        show_on
        state
        ttl
        groupWrite
        createdAt
        updatedAt
        priority
        __typename
      }
      documents {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteJobApplication = /* GraphQL */ `
  mutation DeleteJobApplication(
    $input: DeleteJobApplicationInput!
    $condition: ModelJobApplicationConditionInput
  ) {
    deleteJobApplication(input: $input, condition: $condition) {
      id
      medel_jobApplicationID
      medel_id
      openposition_title
      openpositionID
      gender
      firstname
      lastname
      birthdate
      nationality
      phone
      email
      address
      zipcode
      citystateprovince
      country
      vacancy
      recommendation_person
      hoursperweek
      ja_extenttype
      startdate
      language
      curemplstatus
      exportflag
      createdAt
      updatedAt
      openposition {
        id
        medel_id
        title
        area
        location
        city
        countrycode
        level
        extenttype
        extenttime
        employmenttype
        employmenttime
        activity
        profile
        headerimageID
        salarysupplement
        entrydate
        temporary_position
        contactperson
        contactpersonRefID
        bwusergroup
        objnr
        internal_recommendation_flag
        online_application_flag
        free_text1
        free_text2
        header_tasks
        header_profile
        header_benefits
        header_salarysupplement
        header_entrydate
        header_temporary_position
        header_contactperson
        link
        language
        show_on
        state
        ttl
        groupWrite
        createdAt
        updatedAt
        priority
        __typename
      }
      documents {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createApplicationDocument = /* GraphQL */ `
  mutation CreateApplicationDocument(
    $input: CreateApplicationDocumentInput!
    $condition: ModelApplicationDocumentConditionInput
  ) {
    createApplicationDocument(input: $input, condition: $condition) {
      id
      medel_jobApplicationID
      medel_id
      name
      type
      extension
      path
      link
      original
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateApplicationDocument = /* GraphQL */ `
  mutation UpdateApplicationDocument(
    $input: UpdateApplicationDocumentInput!
    $condition: ModelApplicationDocumentConditionInput
  ) {
    updateApplicationDocument(input: $input, condition: $condition) {
      id
      medel_jobApplicationID
      medel_id
      name
      type
      extension
      path
      link
      original
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteApplicationDocument = /* GraphQL */ `
  mutation DeleteApplicationDocument(
    $input: DeleteApplicationDocumentInput!
    $condition: ModelApplicationDocumentConditionInput
  ) {
    deleteApplicationDocument(input: $input, condition: $condition) {
      id
      medel_jobApplicationID
      medel_id
      name
      type
      extension
      path
      link
      original
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      title {
        de
        en
        __typename
      }
      description {
        de
        en
        __typename
      }
      link {
        de
        en
        __typename
      }
      link_title {
        de
        en
        __typename
      }
      type
      changeStatus
      publicVisible
      startDateTime
      endDateTime
      city {
        de
        en
        __typename
      }
      country {
        de
        en
        __typename
      }
      isFullDay
      ttl
      groupWrite
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      title {
        de
        en
        __typename
      }
      description {
        de
        en
        __typename
      }
      link {
        de
        en
        __typename
      }
      link_title {
        de
        en
        __typename
      }
      type
      changeStatus
      publicVisible
      startDateTime
      endDateTime
      city {
        de
        en
        __typename
      }
      country {
        de
        en
        __typename
      }
      isFullDay
      ttl
      groupWrite
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      title {
        de
        en
        __typename
      }
      description {
        de
        en
        __typename
      }
      link {
        de
        en
        __typename
      }
      link_title {
        de
        en
        __typename
      }
      type
      changeStatus
      publicVisible
      startDateTime
      endDateTime
      city {
        de
        en
        __typename
      }
      country {
        de
        en
        __typename
      }
      isFullDay
      ttl
      groupWrite
      createdAt
      updatedAt
      __typename
    }
  }
`;
