import {createInstance} from "@jonkoops/matomo-tracker-react";

const MATOMO_URL_BASE = process.env.REACT_APP_MATOMO_URL_BASE;
const MATOMO_SRC_URL = process.env.REACT_APP_MATOMO_SRC_URL;

export const matomo_instance = createInstance({
    urlBase: MATOMO_URL_BASE,
    siteId: 10,
    srcUrl: MATOMO_SRC_URL, // optional, default value: `${urlBase}matomo.js`
    //trackerUrl: "https://LINK.TO.DOMAIN/tracking.php", // optional, default value: `${urlBase}matomo.php`
    //disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
    //heartBeat: {
    //    // optional, enabled by default
    //    active: true, // optional, default value: true
    //    seconds: 10 // optional, default value: `15
    //},
    //linkTracking: true, // optional, default value: true
    //configurations: {
    //    // optional, default value: {}
    //    // any valid matomo configuration, all below are optional
    //    disableCookies: true,
    //    setSecureCookie: true,
    //    setRequestMethod: "POST"
    //}

});