import React, { useState, useEffect, Suspense} from "react";
import {SizeProvider} from "./contexts/SizeContext";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { HelmetProvider } from "react-helmet-async";

import {cognitoSSOConfig} from "./aws-config";
import Amplify from "aws-amplify";
import AllRoutes from "./routes";
import awsconfig from "./aws-exports";
import {BrowserRouter as Router} from "react-router-dom";
import FallbackLoader from "./components/FallbackLoader";

Amplify.configure({...awsconfig, ...cognitoSSOConfig});

function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
                mobileMenu: window.innerWidth < 767 ? true : false
            });
        }
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}



function App() {
    const windowSize = useWindowSize();

    return (
        <HelmetProvider>
            <I18nextProvider i18n={i18n}>
                <SizeProvider value={windowSize}>
                        <div className="App">
                            <Router>
                                <Suspense fallback={<FallbackLoader/>}>
                                    <AllRoutes />
                                </Suspense>
                            </Router>
                        </div>
                </SizeProvider>
            </I18nextProvider>
        </HelmetProvider>
    );
}

export default App;
