/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listOpenPositions = /* GraphQL */ `
  query ListOpenPositions(
    $filter: ModelOpenPositionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOpenPositions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        medel_id
        title
        area
        location
        city
        countrycode
        level
        extenttype
        extenttime
        employmenttype
        employmenttime
        activity
        profile
        benefits {
          items {
            benefit {
                id
                icon
                description_de
                description_en
            }
          }
          nextToken
        }
        headerimageID
        salarysupplement
        temporary_position
        entrydate
        contactperson
        bwusergroup
        objnr
        internal_recommendation_flag
        online_application_flag
        free_text1
        free_text2
        header_tasks
        header_profile
        header_benefits
        header_salarysupplement
        header_entrydate
        header_temporary_position
        header_contactperson
        link
        language
        show_on
        state
        groupWrite
        createdAt
        updatedAt
        priority
        contactpersonRefID
        contactpersonRef {
            id
            firstName
            lastName
            imgPath
            email
            text
            groupWrite
            createdAt
            updatedAt
          }
      }
      nextToken
    }
  }
`;

export const openpositionByMedelIDWithBenefits = /* GraphQL */ `
  query OpenpositionByMedelID(
    $medel_id: ID
    $show_on: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOpenPositionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    openpositionByMedelID(
      medel_id: $medel_id
      show_on: $show_on
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
          id 
          medel_id 
          title 
          area
          location 
          city 
          countrycode 
          level 
          extenttype
          extenttime 
          employmenttype
          employmenttime 
          activity 
          profile 
          benefits {
            items {
                benefit { 
                    icon 
                    description_de 
                    description_en
                }
            }
          } 
          headerimageID 
          headerimage {
            id 
            filepath 
          } 
          salarysupplement 
          temporary_position 
          entrydate 
          contactperson 
          online_application_flag 
          free_text1 
          free_text2 
          header_tasks 
          header_profile 
          header_benefits 
          header_salarysupplement 
          header_entrydate 
          header_temporary_position 
          header_contactperson 
          language 
          show_on 
          state
          contactpersonRefID
          contactpersonRef {
             id
             firstName
             lastName
             imgPath
             email
             text
             groupWrite
             createdAt
             updatedAt
          }
      }
    }
  }
`;

export const jobApplicationByExportFlagMinimal = /* GraphQL */ `
  query JobApplicationByExportFlag(
    $exportflag: Export
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJobApplicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobApplicationByExportFlag(
      exportflag: $exportflag
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        medel_jobApplicationID
        medel_id
        openpositionID
        exportflag
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listEventsForOverview = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        title {
            de
            en
        }
        description {
            de
            en
        }
        link {
            de
            en
        }
        link_title {
            de
            en
        }
        changeStatus
        publicVisible
        startDateTime
        endDateTime
        city {
            de
            en
        }
        country {
            de
            en
        }
        isFullDay
        ttl
        groupWrite
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const eventsByPublishedAndEndDateTime = /* GraphQL */ `
  query EventsByPublishedAndEndDateTime(
    $publicVisible: PublicVisible
    $endDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventsByPublishedAndEndDateTime(
      publicVisible: $publicVisible
      endDateTime: $endDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        title {
            de
            en
        }
        description {
            de
            en
        }
        link {
            de
            en
        }
        link_title {
            de
            en
        }
        changeStatus
        publicVisible
        startDateTime
        endDateTime
        city {
            de
            en
        }
        country {
            de
            en
        }
        isFullDay
        ttl
        groupWrite
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;