import 'devextreme/dist/css/dx.light.css';
import 'devextreme-react/text-area';
import 'devextreme-react/html-editor';
import '../../App.css';
import React from "react";
import Amplify, {API, Auth} from 'aws-amplify';
import DataGrid, {
    ColumnChooser,
    SearchPanel,
    Column,
    LoadPanel,
    Editing,
    Pager,
    Paging,
    Popup,
    FilterRow,
    Form, Button as DataGridButton
} from 'devextreme-react/data-grid';
import {
    Item, GroupItem, SimpleItem, TabbedItem,
    Tab, AsyncRule,
    TabPanelOptions, RequiredRule
} from 'devextreme-react/form';
import {Popup as FileUploaderPopup} from "devextreme-react/popup";
import { SpeedDialAction } from 'devextreme-react/speed-dial-action';
import awsconfig from '../../aws-exports';
import {
    createOpenPosition, createOpenPositionBenefit,
    deleteOpenPositionBenefit,
    updateOpenPosition
} from "../../graphql/mutations";
import ManagePageHeadline from "../ManagePageHeadline";
import NavBar from "../Menu/NavBar";
import {ManagementMenuItems_DEFAULT, ManagementMenuItems_DE} from "../../menuItems";
import notify from "devextreme/ui/notify";
import {listOpenPositions} from "../../graphql/custom-queries";
import {listBenefits, listHeaderImages, openPositionBenefitByOpenPosition} from "../../graphql/queries";
import {schema} from "../../models/schema";
import FileUploader from "devextreme-react/file-uploader";
import "devextreme-react/tag-box";
import Button from "../Button";
import {idgenerator} from "../../settings/bwidgenerator";
import {withTranslation} from "react-i18next";
import i18n from "i18next";
import i18next from "i18next";
import enMessages from "devextreme/localization/messages/en.json";
import { locale, loadMessages } from "devextreme/localization";
import { isValidURL } from "../../settings/validation";
import {withAuthenticator} from "@aws-amplify/ui-react";
import Header from "../Header";

Amplify.configure(awsconfig);

const pageSizes = [10, 25, 50, 100, 'all'];

class ManagePositions extends React.Component {

    constructor(props) {
        super(props);
        let language = i18n.language.toUpperCase().substring(0, 2);
        if (!['EN','DE'].includes(language)) {
            language = 'EN';
        }
        this.state = {
            lang: language,
            maxItems: 6, // max number of benefits
            groupClaim: "custom:user_location", // this corresponds to the groupClaim in the schema.graphql; adaptable for tests
            groupWrite_permission: [], // stores a list of permissions for groupWrite, corresponding to the schema
            htmleditor_items: [
                "bold",
                "italic",
                "underline",
                "color",
                "background",
                "link"
            ],
            openpositions: [],
            headerimages:[],
            all_benefits:[],
            states: schema.enums.State.values,
            priorities: schema.enums.Priority.values,
            languages: ['DE','EN'],
            show_on_types: ['DE','EN', 'ALL'],
            recommendation_flags: schema.enums.Recommendation.values,
            online_application_flags: schema.enums.OnlineApplication.values,
            selectedfiles: [],
            form_type: 'DEFAULT' // currently allowed values: DEFAULT (for AT etc.), DE
        };
        //loadMessages(deMessages);
        loadMessages(enMessages);
        locale("en");
        this.onImportDPWClick = this.onImportDPWClick.bind(this);
        this.importDPW = this.importDPW.bind(this);
        this.onSelectedFilesChanged = this.onSelectedFilesChanged.bind(this);
        this.cloneIconClick = this.cloneIconClick.bind(this);
        this.renderStatusCell = this.renderStatusCell.bind(this);
        this.handleAuthStateChange = this.handleAuthStateChange.bind(this);
        this.isValidURL = this.isValidURL.bind(this);
    }

    handleAuthStateChange(e) {
        if(e === "signedin") {
            this.setState({authenticated: true});
        } else {
            this.setState({authenticated: false})
        }
    }

    async componentDidMount(){
        document.body.scrollTop = 0;
        window.scrollTo(0,0);
        this.fetchAllOpenPositions();
        this.fetchAllBenefits();
        this.fetchAllHeaderImages();
        i18next.on('languageChanged', () => {
            //locale(i18n.language);
            this.setState({lang: i18n.language.toUpperCase().substring(0, 2)});
        });
        Auth.currentCredentials()
            .then(cred=> this.setState({authenticated: cred.authenticated}))
            .catch(err => console.error({err}));
        // generate the group claim permissions that the user will set on creating new Open Positions
        Auth.currentSession()
            .then(cred=> {
                //console.log(cred);
                // activate the following snippet in order to join Medel DE
                let claim = "AT" //cred.getIdToken().decodePayload()[this.state.groupClaim]
                //console.log(claim);
                this.setState({groupWrite_permission: [claim], form_type: (claim === "AT" ? "DEFAULT" : claim)})})
            .catch(err => console.error({err}));
    }

    componentWillUnmount() {
        i18next.off('languageChanged');
    }

    async isValidURL(params) {
        let url = params.value;
        return isValidURL(url);
    }

    render() {
        /*id title area extenttype extenttime employmenttype employmenttime level regions{city country countrycode} state*/
        const {t} = this.props;
            return (
                <div className="ManagePositions">
                    <Header displayTop={true} mobile={false}/>
                    <NavBar subpage items={this.state.form_type === "DE" ? ManagementMenuItems_DE : ManagementMenuItems_DEFAULT} signout={true}/>
                    <ManagePageHeadline title="Manage Open Positions"/>
                    <div className="Content">
                        <DataGrid
                            dataSource={this.state.openpositions}
                            keyExpr="id"
                            showBorders={false}
                            defaultFocusedRowIndex={0}
                            allowColumnReordering={true}
                            columnAutoWidth={true}
                            columnHidingEnabled={true}
                            rowAlternationEnabled={true}
                            onRowUpdating={e => this.editPosition(e)}
                            onRowInserting={e => this.createPosition(e)}
                            onRowRemoving={e => this.deletePosition(e)}
                            onEditingStart={this.onEditingStart}
                        >
                            <Editing
                                mode="popup"
                                useIcons={true}
                                columnAutoWidth={true}
                                columnResizingMode={'widget'}
                                allowColumnResizing={true}
                                allowUpdating={true}
                                allowAdding={true}
                                allowDeleting={true}>
                                <Popup title={t('managepositions.popup.title')} showTitle={false} height={"auto"} maxHeight={"100vh"}/>
                                <Form colCount={2} showValidationSummary={false}>
                                    <GroupItem caption={t('managepositions.popup.group_item.title1')} colSpan={1}>
                                        <Item dataField="medel_id" colSpan={1}>
                                            <RequiredRule/>
                                        </Item>
                                        {this.state.form_type === 'DEFAULT' ?
                                            <Item dataField="bwusergroup" colSpan={2}>
                                                <RequiredRule/>
                                            </Item> : null }
                                        {this.state.form_type === 'DEFAULT' ?
                                            <Item dataField="objnr" colSpan={2}>
                                                <RequiredRule/>
                                            </Item> : null }
                                        <Item dataField="title" colSpan={2}>
                                            <RequiredRule/>
                                        </Item>
                                        <Item dataField="area" colSpan={2}>
                                            <RequiredRule/>
                                        </Item>
                                        <Item dataField="level" editorType="dxSelectBox" editorOptions={{
                                            items: t('EntryLevels',{ returnObjects: true }),
                                            valueExpr: "value",
                                            displayExpr: "name",
                                            searchEnabled: true,
                                        }}><RequiredRule message={"Employment Tab: Level is required"}/></Item>
                                        {this.state.form_type === 'DEFAULT' ?
                                            <Item dataField="benefits" editorType="dxTagBox" editorOptions={{
                                                items: this.state.all_benefits,
                                                searchEnabled: true,
                                                valueExpr: "id",
                                                displayExpr: "description_en",
                                                showSelectionControls: true,
                                                applyValueMode: "useButtons"
                                            }}></Item> : null}
                                        {this.state.form_type === 'DE' ?
                                            <Item dataField="link" colSpan={2}>
                                                <RequiredRule/>
                                                <AsyncRule message="The external link is not valid, e.g. https://example.com" validationCallback={this.isValidURL}/>
                                            </Item>
                                            : null}
                                    </GroupItem>
                                    <GroupItem caption={t('managepositions.popup.group_item.title2')} colSpan={1}>
                                        <Item dataField="state" colSpan={1} editorType="dxSelectBox" editorOptions={{
                                            items: this.state.states,
                                            searchEnabled: true
                                        }}>
                                            <RequiredRule/>
                                        </Item>
                                        <Item dataField="priority" editorType="dxSelectBox" editorOptions={{
                                            items: this.state.priorities,
                                            searchEnabled: true
                                        }}>
                                            <RequiredRule/>
                                        </Item>
                                        <Item dataField="show_on" editorType="dxSelectBox"
                                              editorOptions={{
                                                  items: this.state.show_on_types
                                              }}><RequiredRule/></Item>
                                        {this.state.form_type === 'DEFAULT' ?
                                            <Item dataField="internal_recommendation_flag" editorType="dxSelectBox"
                                              editorOptions={{
                                                  items: this.state.recommendation_flags
                                              }}></Item> : null }
                                        {this.state.form_type === 'DEFAULT' ? <Item dataField="online_application_flag" editorType="dxSelectBox"
                                              editorOptions={{
                                                  items: this.state.online_application_flags
                                              }}></Item> : null }
                                    </GroupItem>
                                    <GroupItem caption={t('managepositions.popup.group_item.title3')} colSpan={2}>
                                        <TabbedItem>
                                            <TabPanelOptions/>
                                            <Tab title={t('managepositions.popup.tab.title1')} colCount={2}>
                                                <GroupItem colSpan={1}>
                                                    <Item dataField="extenttime"/>
                                                    <Item dataField="extenttype" editorType="dxSelectBox" editorOptions={{
                                                        items: t('ExtentTypesCMS',{ returnObjects: true }),
                                                        valueExpr: "value",
                                                        displayExpr: "name",
                                                        searchEnabled: true,
                                                    }}><RequiredRule
                                                        message={"Employment Tab: Extent Type is required"}/></Item>
                                                </GroupItem>
                                                <GroupItem colSpan={1}>
                                                    <Item dataField="employmenttime"/>
                                                    <Item dataField="employmenttype" editorType="dxSelectBox"
                                                          editorOptions={{
                                                              items: t('EmploymentTypes',{ returnObjects: true }),
                                                              valueExpr: "value",
                                                              displayExpr: "name",
                                                              searchEnabled: true,
                                                          }}/>
                                                </GroupItem>
                                            </Tab>
                                            <Tab title={t('managepositions.popup.tab.title2')}>
                                                <Item dataField="location"><RequiredRule
                                                    message={"Location: location is required"}/></Item>
                                                <Item dataField="city"><RequiredRule
                                                    message={"Location: city is required"}/></Item>
                                                <Item dataField="countrycode"><RequiredRule
                                                    message={"Location: country code is required, e.g. AT, DE, etc."}/></Item>
                                            </Tab>
                                            { this.state.form_type === 'DEFAULT' ?
                                            <Tab title={t('managepositions.popup.tab.title3')}>
                                                <SimpleItem dataField={"activity"} editorType="dxTextArea"
                                                            editorOptions={{height: "250"}}><RequiredRule
                                                    message={"Activity Tab: activity is required"}/></SimpleItem>
                                            </Tab> : null }
                                            { this.state.form_type === 'DEFAULT' ?
                                            <Tab title={t('managepositions.popup.tab.title4')}>
                                                <SimpleItem dataField={"profile"} editorType="dxTextArea"
                                                            editorOptions={{height: "250"}}><RequiredRule
                                                    message={"Profile Tab: profile is required"}/></SimpleItem>
                                            </Tab> : null }
                                            { this.state.form_type === 'DEFAULT' ?
                                                <Tab title={t('managepositions.popup.tab.title5')} colCount={2}>
                                                    <SimpleItem dataField={"salarysupplement"} editorType="dxHtmlEditor"
                                                                editorOptions={{height: "160", toolbar:{items: this.state.htmleditor_items}, allowSoftLineBreak: true}} colSpan={1}/>
                                                    <SimpleItem dataField={"entrydate"} editorType="dxHtmlEditor"
                                                                editorOptions={{height: "160", toolbar:{items: this.state.htmleditor_items}, allowSoftLineBreak: true}} colSpan={1}/>
                                                    <SimpleItem dataField={"temporary_position"} editorType="dxHtmlEditor"
                                                                editorOptions={{height: "160", toolbar:{items: this.state.htmleditor_items}, allowSoftLineBreak: true}} colSpan={1}/>
                                                    <SimpleItem dataField={"contactperson"} editorType="dxHtmlEditor"
                                                                editorOptions={{height: "160", toolbar:{items: this.state.htmleditor_items}, allowSoftLineBreak: true}} colSpan={1}/>
                                                </Tab> : null }
                                            { this.state.form_type === 'DEFAULT' ?
                                                <Tab title={t('managepositions.popup.tab.title6')}>
                                                    <SimpleItem dataField={"free_text1"} editorType="dxHtmlEditor"
                                                                editorOptions={{toolbar:{items: this.state.htmleditor_items}, allowSoftLineBreak: true}}/>
                                                    <SimpleItem dataField={"free_text2"} editorType="dxHtmlEditor"
                                                                editorOptions={{toolbar:{items: this.state.htmleditor_items}, allowSoftLineBreak: true}}/>
                                                <SimpleItem dataField={"header_tasks"}/>
                                                <SimpleItem dataField={"header_profile"}/>
                                                <SimpleItem dataField={"header_benefits"}/>
                                                <SimpleItem dataField={"header_salarysupplement"}/>
                                                <SimpleItem dataField={"header_entrydate"}/>
                                                <SimpleItem dataField={"header_temporary_position"}/>
                                                <SimpleItem dataField={"header_contactperson"}/>
                                            </Tab> : null }
                                            { this.state.form_type === 'DEFAULT' ?
                                            <Tab title={t('managepositions.popup.tab.title7')}>
                                                <Item dataField="headerimageID" editorType="dxSelectBox"
                                                      editorOptions={{
                                                          items: this.state.headerimages,
                                                          valueExpr: "id",
                                                          displayExpr: "id",
                                                          searchEnabled: true
                                                      }}></Item>
                                            </Tab> : null }
                                        </TabbedItem>
                                    </GroupItem>
                                </Form>
                            </Editing>
                            <ColumnChooser enabled={true} mode="select"/>
                            <LoadPanel enabled/>
                            <SearchPanel visible={true} width={"auto"}/>
                            <FilterRow/>
                            <Paging defaultPageSize={10}/>
                            <Pager
                                visible={true}
                                allowedPageSizes={pageSizes}
                                showPageSizeSelector="true"
                                showInfo="true"/>
                            <FilterRow visible={true}/>
                            {this.state.form_type === 'DEFAULT' ?
                                <SpeedDialAction
                                icon="upload"
                                label="Upload"
                                index={1}
                                onClick={() => {
                                    this.setState({showFileUploaderPopup: true})
                                }}/> : null}
                            <Column type="buttons" width={120}>
                                <DataGridButton name="edit" />
                                <DataGridButton name="delete" />
                                <DataGridButton hint="Clone" icon="copy" visible={true} disabled={false} onClick={this.cloneIconClick} />
                                <DataGridButton hint="Copy link to Clipboard" icon="link" visible={true} disabled={false} onClick={this.generateOnlineLink} />
                            </Column>
                            <Column dataField="medel_id" groupIndex={0} sortOrder="asc" caption={t('managepositions.medel_id')}/>
                            <Column dataField="title" caption={t('managepositions.title')}/>
                            <Column dataField="state" cellRender={this.renderStatusCell} caption={t('managepositions.state')}/>
                            {this.state.form_type === 'DEFAULT' ?
                                <Column dataField="internal_recommendation_flag" caption={t('managepositions.internal_recommendation_flag')}/> : null}
                            {this.state.form_type === 'DEFAULT' ?
                                <Column dataField="bwusergroup" caption={t('managepositions.bwusergroup')}/> : null }
                            {this.state.form_type === 'DEFAULT' ?
                                <Column dataField="objnr" caption={t('managepositions.objnr')}/> : null }
                            <Column dataField="area" caption={t('managepositions.area')}/>
                            <Column dataField="location" caption={t('managepositions.location')}/>
                            <Column dataField="city" caption={t('managepositions.city')}/>
                            <Column dataField="countrycode" caption={t('managepositions.countrycode')}/>
                            <Column dataField="priority" caption={t('managepositions.priority')}/>
                            <Column dataField="show_on" caption={t('managepositions.show_on')}/>
                            {this.state.form_type === 'DE' ?
                                <Column dataField="link" caption={t('managepositions.link')}/> : null }
                            {this.state.form_type === 'DEFAULT' ?
                                <Column dataField="internal_recommendation_flag" caption={t('managepositions.internal_recommendation_flag')}/> : null}
                            {this.state.form_type === 'DEFAULT' ?
                                <Column dataField="online_application_flag" caption={t('managepositions.online_application_flag')}/> : null}
                            {this.state.form_type === 'DEFAULT' ?
                                <Column dataField="activity" caption={t('managepositions.activity')}/> : null}
                            {this.state.form_type === 'DEFAULT' ?
                                <Column dataField="profile" caption={t('managepositions.profile')}/> : null}
                            {this.state.form_type === 'DEFAULT' ?
                                <Column dataField="benefits" caption={t('managepositions.benefits')}/> : null}
                            {this.state.form_type === 'DEFAULT' ?
                                <Column dataField="headerimageID" caption={t('managepositions.headerimageID')}/> : null}
                            {this.state.form_type === 'DEFAULT' ?
                                <Column dataField="headerimage" caption={t('managepositions.headerimage')}/> : null}
                            {this.state.form_type === 'DEFAULT' ?
                                <Column dataField="salarysupplement" caption={t('managepositions.salarysupplement')}/> : null}
                            {this.state.form_type === 'DEFAULT' ?
                                <Column dataField="entrydate" caption={t('managepositions.entrydate')}/> : null}
                            {this.state.form_type === 'DEFAULT' ?
                                <Column dataField="temporary_position" caption={t('managepositions.temporary_position')}/> : null}
                            {this.state.form_type === 'DEFAULT' ?
                                <Column dataField="contactperson" caption={t('managepositions.contactperson')}/> : null}
                            {this.state.form_type === 'DEFAULT' ?
                                <Column dataField="free_text1" caption={t('managepositions.free_text1')}/> : null}
                            {this.state.form_type === 'DEFAULT' ?
                                <Column dataField="free_text2" caption={t('managepositions.free_text2')}/> : null}
                            {this.state.form_type === 'DEFAULT' ?
                                <Column dataField="header_tasks" caption={t('managepositions.header_tasks')}/> : null}
                            {this.state.form_type === 'DEFAULT' ?
                                <Column dataField="header_profile" caption={t('managepositions.header_profile')}/> : null}
                            {this.state.form_type === 'DEFAULT' ?
                                <Column dataField="header_benefits" caption={t('managepositions.header_benefits')}/> : null}
                            {this.state.form_type === 'DEFAULT' ?
                                <Column dataField="header_salarysupplement" caption={t('managepositions.header_salarysupplement')}/> : null}
                            {this.state.form_type === 'DEFAULT' ?
                                <Column dataField="header_entrydate" caption={t('managepositions.header_entrydate')}/> : null}
                            {this.state.form_type === 'DEFAULT' ?
                                <Column dataField="header_temporary_position"
                                                                          caption={t('managepositions.header_temporary_position')}/> : null}
                            {this.state.form_type === 'DEFAULT' ?
                                <Column dataField="header_contactperson" caption={t('managepositions.header_contactperson')}/> : null}
                            <Column dataField="level" caption={t('managepositions.level')}/>
                            <Column dataField="extenttype" caption={t('managepositions.extenttype')}/>
                            <Column dataField="extenttime" caption={t('managepositions.extenttime')}/>
                            <Column dataField="employmenttype" caption={t('managepositions.employmenttype')}/>
                            <Column dataField="employmenttime" caption={t('managepositions.employmenttime')}/>
                            <Column dataField="createdAt" caption={t('managepositions.createdAt')} dataType="datetime" format="dd.MM.yyyy, hh:mm"/>
                        </DataGrid>
                        <FileUploaderPopup title="Upload and import DPW file" showTitle={true}
                                           visible={this.state.showFileUploaderPopup}
                                           onHiding={() => {
                                               this.setState({showFileUploaderPopup: false})
                                           }}
                                           dragEnabled={true}
                                           closeOnOutsideClick={true}
                                           showCloseButton={true}
                                           container="ManagePositions"
                                           height={"350px"}>
                            <form action="importPositionsFromXmlFile" onSubmit={this.onImportDPWClick}>
                                <FileUploader
                                    id="file-uploader"
                                    name={"importxmlfile"}
                                    selectButtonText="Select file"
                                    maxFileSize={4000000}
                                    multiple={false}
                                    labelText="Drop your files here or.."
                                    allowedFileExtensions={['.xml']}
                                    focusStateEnabled={false}
                                    uploadMode="useForm"
                                    showFileList={true}
                                    onValueChanged={this.onSelectedFilesChanged}>
                                </FileUploader>
                                <Button
                                    stylingMode="text"
                                    type="normal"
                                    useSubmitBehavior={true}
                                    disabled={false}
                                    text={"Import"}/>
                            </form>
                        </FileUploaderPopup>
                    </div>
                </div>
            );
    }

    async fetchAllOpenPositions() {
        this.state.openpositions = [];
        try {
            let nt = null;
            do { // this loop is to overcome in a simple manner the limit first and then filter problem
                const {data: {listOpenPositions: {items: page, nextToken}}} = await API.graphql({
                    query: listOpenPositions,
                    variables: {filter: {state: {ne: 'REMOVED'}}, nextToken: nt},
                    authMode: 'AMAZON_COGNITO_USER_POOLS'
                });
                //console.log(page);
                nt = nextToken;
                page.forEach((pos) => {
                    let mod_benefits = [];
                    if (pos.benefits !== null) {
                        for (let item of pos.benefits.items) {
                            mod_benefits.push(item.benefit.id);
                        }
                        pos['benefits'] = mod_benefits
                    }
                });
                console.log(page);
                this.setState({openpositions: this.state.openpositions.concat(page), nextToken: nt});
            } while (nt !== null)
        } catch (err) { console.error({err}) }
    }

    async fetchAllBenefits() {
        try {
            let nt = null;
            let ab = []
            do { // this loop is to overcome in a simple manner the limit first and then filter problem
                const {data: {listBenefits: {items: page, nextToken}}} = await API.graphql({
                    query: listBenefits,
                    variables: {nextToken: nt},
                    authMode: 'AMAZON_COGNITO_USER_POOLS'
                });
                nt = nextToken;
                page.forEach((item) => {
                    item['icon']=process.env.PUBLIC_URL+'/images/icons/Benefits/'+item['icon'];
                });
                ab = ab.concat(page);
            } while (nt !== null)
            this.setState({all_benefits: ab});
        } catch (err) { console.error({err}) }
    }

    async fetchAllHeaderImages() {
        try {
            let nt = null;
            let ahi = []
            do { // this loop is to overcome in a simple manner the limit first and then filter problem
                const {data: {listHeaderImages: {items: page, nextToken}}} = await API.graphql({
                    query: listHeaderImages,
                    variables: {nextToken: nt},
                    authMode: 'AMAZON_COGNITO_USER_POOLS'
                });
                nt = nextToken;
                ahi = ahi.concat(page);
            } while (nt !== null)
            ahi.sort((a,b) => a.id.localeCompare(b.id));
            this.setState({headerimages: ahi});
        } catch (err) { console.error({err}) }
    }

    /**
     * generates a share link for other platforms
     * @param e {Event} event with the selected datagrid row information
     */
    generateOnlineLink(e) {
        let data = e.row.data;
        let link = data.link ? data.link : `https://jobs.medel.com/open-position/${data.medel_id}/${data.show_on}/online`
        // write to clipboard
        navigator.permissions.query({name: "clipboard-write"}).then(result => {
            if (result.state === "granted" || result.state === "prompt") {
                navigator.clipboard.writeText(link).then((e) => {
                    notify({message: "Link copied to clipboard"}, "success", 2000);
                });
            } else {
                prompt("Copy to Clipboard was not successful/blocked.\nPlease find the link here:",link);
            }
        });
    }

    /**
     * clones a selected open position
     * @param e {Event} event with the selected datagrid row information
     */
    async cloneIconClick(e) {
        let data = e.row.data;
        let new_pos_id = idgenerator();
        let benefits = data.benefits;
        // delete benefits and updatedAt from clone
        const positions = [...this.state.openpositions];
        let clonedItem = { ...data, id: new_pos_id, state: 'INACTIVE'};
        delete clonedItem.benefits;
        delete clonedItem.updatedAt;
        // clone OpenPosition
        let promises = [];
        try {
            promises.push(API.graphql({
                query: createOpenPosition,
                authMode: 'AMAZON_COGNITO_USER_POOLS',
                variables: {input: clonedItem}}));
        } catch (err) {
            console.error({err});
        }
        // console.log(benefits);
        // clone Benefits
        try {
            for (const b of benefits) {
                promises.push(API.graphql({
                    query: createOpenPositionBenefit,
                    authMode: 'AMAZON_COGNITO_USER_POOLS',
                    variables: {input: {id: idgenerator(), benefitID: b, openpositionID: new_pos_id, groupWrite: this.state.groupWrite_permission}}}));
            }
        } catch (err) {
            console.error({err});
        }
        //console.log(benefits);
        // to avoid new reloading, not working yet
        positions.splice(e.row.rowIndex, 0, { ...clonedItem, benefits:benefits});
        this.setState({ openpositions: positions });
        Promise.allSettled(promises).then(
            results => {
                notify({ message: "OpenPosition cloned" }, "success", 2000);
                console.debug(results);
            }
        );
        e.event.preventDefault();
    }

    async editPosition(event) {
        // console.debug(event);
        let key = {id: event.key};
        if ('benefits' in event.newData) {
            let new_b = event.newData.benefits;
            //let old_b = event.oldData.benefits;
            // check if the number of benefits exceeds the max.
            if (new_b.length > this.state.maxItems) {
                event.cancel = true
                notify({message: "The max. number of Benefits is 6!"}, "warning", 3000);
                return;
            }
            //let intersection = new_b.filter(x => old_b.includes(x));
            // delete all old benefits which are not part of intersection
            //let b_to_del = old_b.filter(x => !intersection.includes(x));
            // delete all old benefits
            try {
                const {data: {openPositionBenefitByOpenPosition: {items: opb}}} =  await API.graphql({query: openPositionBenefitByOpenPosition, variables: {openpositionID: key.id}});
                opb.forEach(b => {
                    API.graphql({query: deleteOpenPositionBenefit, authMode: 'AMAZON_COGNITO_USER_POOLS', variables: {input: {id: b.id}}})
                        .then(result => console.debug(result))
                        .catch(err => console.error({err}));
                });
            } catch (err) {
                console.error({err});
            }
            // create all new benefits which are not part of intersection
            //let b_to_add = new_b.filter(x => !intersection.includes(x));
            try {
                new_b.forEach(b => {
                    API.graphql({query: createOpenPositionBenefit, authMode: 'AMAZON_COGNITO_USER_POOLS', variables: {input: {id: idgenerator(), benefitID: b, openpositionID: key.id, groupWrite: event.oldData.groupWrite}}})
                        .then(result =>  console.debug(result))
                        .catch(err => console.error({err}));
                });
            } catch (err) {
                console.error({err});
            }
            delete event.newData.benefits;
        }
        const items = {...key, ...event.newData}
        try {
            await API.graphql({query: updateOpenPosition, authMode: 'AMAZON_COGNITO_USER_POOLS', variables: {input: items}});
            notify({message: "Successfully edited Open Position."}, "success", 3000)
        } catch (err) {
            notify({message: "Error on editing Open Position"}, "error", 3000);
            console.error({err});
            event.cancel = true;
        }
    }

    async createPosition(event) {
        // console.log(event);
        if ('benefits' in event.data) {
            let new_b = event.data.benefits;
            // check if the number of benefits exceeds the max.
            if (new_b.length > this.state.maxItems) {
                event.cancel = true
                notify({message: "The max. number of Benefits is 6!"}, "warning", 3000);
                return;
            }
            new_b.forEach(b => {
                let items = {
                    id: event.data.id+'-'+event.data.benefitID,
                    benefitID: b,
                    openpositionID: event.data.id,
                    groupWrite: this.state.groupWrite_permission}
                API.graphql({query: createOpenPositionBenefit, authMode: 'AMAZON_COGNITO_USER_POOLS', variables: {input: items}})
                    .then(result => console.debug(result))
                    .catch(err => console.error({err}));
            });
            delete event.data.benefits;
        }
        // add groupWrite permission to event.data
        event.data["groupWrite"] = this.state.groupWrite_permission;
        API.graphql({query: createOpenPosition, authMode: 'AMAZON_COGNITO_USER_POOLS', variables: {input: event.data}})
            .then(() => notify({message: "Successfully created new Open Position."}, "success", 3000))
            .catch(err => {notify({message: "Error on creating new Open Position"}, "error", 3000);
                console.error({err});
                event.cancel = true;});
    }

    // sets the status to REMOVED to keep the data available for export up to 1 month
    async deletePosition(event) {
        const TTL_DELTA = 2630000; // Keep records for 1 months in seconds
        const ttl = (Math.floor(+new Date() / 1000) + TTL_DELTA);
        const items = {id: event.key, state: 'REMOVED', ttl: ttl};

        // delete all old benefits
        try {
            const {data: {openPositionBenefitByOpenPosition: {items: opb}}} =  await API.graphql({query: openPositionBenefitByOpenPosition, variables: {openpositionID: event.key.id}});
            opb.forEach((b) => {
                API.graphql({query: deleteOpenPositionBenefit, authMode: 'AMAZON_COGNITO_USER_POOLS', variables: {input: {id: b.id}}})
                    .then(result => console.debug(result))
                    .catch(err => console.error({err}));
            });
        } catch (err) {
            console.error({err});
        }
        // mark the OpenPosition as REMOVED and set ttl
        API.graphql({query: updateOpenPosition, authMode: 'AMAZON_COGNITO_USER_POOLS', variables: {input: items}})
            .then(() => notify({message: "Successfully deleted Open Position."}, "success", 3000))
            .catch(err => {
                notify({message: "Error on deleting Open Position."}, "error", 3000);
                console.error({err});
                event.cancel = true;
            });
    }

    /**
     * handles the Upload Button click
     * @param event
     */
    onImportDPWClick(event) {
        event.preventDefault();
        this.setState({showFileUploaderPopup:false});
        if (this.state.selectedfiles.length === 1) {
            this.state.selectedfiles[0].text().then(t => this.importDPW(t));
        }
    }

    /**
     * import jobs from an XML file of a predefined style by MEDEL
     * @param content {String} of jobs xml file
     * @returns {}
     */
    async importDPW(content) {
        let parser = new DOMParser();
        let xmlDoc = parser.parseFromString(content, "text/xml");
        let promises = [];
        for (const job of xmlDoc.getElementsByTagName("job")) {
            try {
                const extenttime = job.getElementsByTagName("Ausschreibung_Stundenausmaß").length > 0 ? job.getElementsByTagName("Ausschreibung_Stundenausmaß")[0].textContent : "";
                const medel_id = job.getElementsByTagName("Ausschreibung_Code").length > 0 ? job.getElementsByTagName("Ausschreibung_Code")[0].textContent : "";
                const title = job.getElementsByTagName("title").length > 0 ? job.getElementsByTagName("title")[0].textContent : "";
                promises.push(
                    API.graphql({
                        query: "query openpositionByMedelID {openpositionByMedelID(medel_id: \"" + medel_id + "\", sortDirection: DESC){items {id medel_id title state}}}",
                        authMode: 'AMAZON_COGNITO_USER_POOLS'
                    }).then(({data: {openpositionByMedelID: {items: [openposition]}}}) => {
                        console.debug(openposition + " " + medel_id);
                        if (openposition === undefined) {
                            API.graphql({
                                query: createOpenPosition,
                                variables: {
                                    input: {
                                        id: idgenerator(),
                                        medel_id: medel_id,
                                        title: title,
                                        area: job.getElementsByTagName("Sub_Bereich").length > 0 ? job.getElementsByTagName("Sub_Bereich")[0].textContent : "",
                                        location: job.getElementsByTagName("Ausschreibung_Standort").length > 0 ? job.getElementsByTagName("Ausschreibung_Standort")[0].textContent : "",
                                        extenttime: extenttime,
                                        //extenttype: (parseFloat(extenttime) < parseFloat("38,50") ? 'TZ' : 'VZ'),
                                        activity: job.getElementsByTagName("activityText").length > 0 ? job.getElementsByTagName("activityText")[0].textContent.replaceAll("•", "") : "",
                                        profile: job.getElementsByTagName("Ausschreibung_Voraussetzungen").length > 0 ? job.getElementsByTagName("Ausschreibung_Voraussetzungen")[0].textContent.replaceAll("•", "") : "",
                                        salarysupplement: job.getElementsByTagName("Gehaltszusatz").length > 0 ? job.getElementsByTagName("Gehaltszusatz")[0].textContent.replaceAll("•", "") : "",
                                        bwusergroup: job.getElementsByTagName("Ausschreibung_BW_Benutzergruppe").length > 0 ? job.getElementsByTagName("Ausschreibung_BW_Benutzergruppe")[0].textContent : "",
                                        objnr: job.getElementsByTagName("Ausschreibung_ObjNr").length > 0 ? job.getElementsByTagName("Ausschreibung_ObjNr")[0].textContent : "",
                                        createdAt: (new Date()).toISOString(),//job.getElementsByTagName("creationDate")[0].textContent,
                                        show_on: 'ALL',
                                        state: 'INACTIVE',
                                        internal_recommendation_flag: 'OFF',
                                        online_application_flag: 'ON',
                                        priority: 'NORMAL',
                                        groupWrite: this.state.groupWrite_permission
                                    },
                                },
                                authMode: 'AMAZON_COGNITO_USER_POOLS'
                            })
                                .then(result => console.debug(result))
                                .catch(err => console.error({err}));
                        }
                    }));
            } catch (error) {
                console.error(error);
            }
        }
        Promise.allSettled(promises).then(
            results => {
                this.fetchAllOpenPositions();
                /*let nr_imported = 0;
                results.forEach((result) => {
                    if (result.status.includes("fulfilled")) {
                        nr_imported++;
                    }
                });*/
                notify({ message: "OpenPositions imported" }, "success", 2000);
                console.debug(results);
            }
        );
    }

    onSelectedFilesChanged(event) {
        this.setState({ selectedfiles: event.value });
    }

    gridCellData(gridData) {
        return gridData.data[gridData.column.caption.toLowerCase()];
    }

    renderStatusCell(data) {
        let color = 'red';
        if (data.value === 'ACTIVE') {
            color = 'green';
        }
        return <div style={{ backgroundColor: color, borderRadius: '50%', width: '1rem',height: '1rem'}} />;
    }
}

export default withAuthenticator(withTranslation()(ManagePositions));
