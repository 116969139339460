export const ManagementMenuItems_DEFAULT = [{
    name: 'Open Positions',
    path: '/manage-positions',
    link: false
}, {
    name: 'Job Applications',
    path: '/manage-jobapplications',
    link: false
}, {
    name: 'Events',
    path: '/manage-events',
    link: false
}, {
    name: 'Contact Messages',
    path: '/manage-contactmessages',
    link: false
}
];

export const ManagementMenuItems_DE = [{
    name: 'Open Positions',
    path: '/manage-positions',
    link: false
}
];

export const FastLinks = [{
    name: 'About MED-EL',
    path: 'https://www.medel.com/about-medel',
    link: true
}, {
    name: 'Press Room',
    path: 'https://www.medel.com/press-room',
    link: true
}, {
    name: 'Contact us',
    path: '/#Contact',
    link: true
}
];