import '../App.css';

function FallbackLoader() {
    return (
        <div className="ld-ind">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
}

export default FallbackLoader;