import '../App.css';

function ManagePageHeadline({title, description}){
    return (
        <div className="ManagePageHeadline">
            <div className="Content">
                <div className="ManagePageTitle">
                    <h1>{title}</h1>
                    {description ? <p>{description}</p>: null}
                </div>
            </div>
        </div>
    );
}

/*ManagePageHeadline.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string
}*/

export default ManagePageHeadline;