import React, {Suspense, lazy} from "react";
import './Header.css';
import {useTranslation} from "react-i18next";
import {generateLink} from "../settings/linkgenerator";
import { Link } from "react-router-dom";

const LanguageSelector = lazy(() => import("./LanguageSelector"));

function Header(props) {
    const [t, i18n] = useTranslation();
    if (props.mobile===true) {
        return null;
    } else {
        return (
            <div className="Header">
                {props.displayTop === false ? null :
                    <Suspense fallback={<div></div>}>
                        <div className="Top no-print">
                            <LanguageSelector/>
                            <div key={"Fastlinks"} className="FastLinks">
                                <a href={t('header.link1')}>{t('header.text1')}</a> | <a
                                href={t('header.link2')}>{t('header.text2')}</a>
                            </div>
                        </div>
                    </Suspense>
                }
                <div className="Bottom">
                    <div className="Bottom-wrapper">
                        {(props.headerText) ?
                            <div className="HeaderText">{props.headerText}</div>
                            : null
                        }
                        {(props.displayLogo === false) ? null :
                            <div className="Logo">
                                <Link to={generateLink('', i18n)}><img src={process.env.PUBLIC_URL + '/images/Logo.svg'} alt="logo"/></Link>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

/*Header.propTypes = {
    mobile: PropTypes.bool.isRequired,
    displayTop: PropTypes.bool,
    displayLogo: PropTypes.bool,
    headerText: PropTypes.string
}*/

export default Header;