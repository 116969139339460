import {
    AmplifyAuthenticator,
    AmplifyAuthContainer, AmplifyButton
} from "@aws-amplify/ui-react";
import "./MedelAuth.css";
import React from "react";
import {Auth} from "aws-amplify";

function MedelAuth({children}) {
        return (
            <AmplifyAuthContainer>
                <AmplifyAuthenticator usernameAlias="username">
                    {/*<AmplifySignIn
                        handleAuthStateChange={this.props.handleAuthStateChange}
                        federated=""
                        headerText="MED-EL Login"
                        slot="sign-in"
                        hideSignUp={true}
                        usernameAlias="username"
                        formFields={[
                            {
                                type: "username",
                                label: "Username *",
                                inputProps: { required: true, autocomplete: "username" },
                                placeholder: 'Enter username'
                            },
                            {
                                type: "password",
                                label: "Password",
                                inputProps: { required: true, autocomplete: "password" },
                            },
                        ]}>
                        <div id="AzureAD-Button" slot="federated-buttons">
                            <AmplifyButton onClick={() => Auth.federatedSignIn()}>Azure AD</AmplifyButton>
                            <hr/>
                        </div>
                    </AmplifySignIn>*/}
                    <div id="AzureAD-Login" slot="sign-in">
                        <h2>MED-EL</h2>
                        <AmplifyButton onClick={() => Auth.federatedSignIn()}>Login</AmplifyButton>
                    </div>
                    {children}
                </AmplifyAuthenticator>
            </AmplifyAuthContainer>
        );
}

export default MedelAuth;