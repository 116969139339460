export function generateLink(path, i18n) {
    //console.log(i18n.language.toUpperCase().substring(0, 2)+path)
    return "/"+i18n.language.toLowerCase().substring(0, 2)+path;
};

// gets the language code and handles the mapping from url language code used by Med-El to ISO code
export function switchLanguage(language, i18n) {
    return (language && ['en', 'de', 'it'].includes(language.toLowerCase()) ?
            i18n.changeLanguage(language.toLowerCase())
            :
            i18n.changeLanguage("en")
    )
};