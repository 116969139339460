const env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : "prod";

export const cognitoSSOConfig = {
    "oauth": {
        "domain": env === "stage" ? "medel.auth.eu-west-1.amazoncognito.com" : "medel-prod.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "email",
            "openid",
            "profile"
        ],
        "redirectSignIn": env === "stage" ? "https://stage.d26dgiodd6jn8m.amplifyapp.com/login/" : "https://prod.d3tm4vssc0r0rj.amplifyapp.com/login/",
        "redirectSignOut": env === "stage" ? "https://stage.d26dgiodd6jn8m.amplifyapp.com" : "https://prod.d3tm4vssc0r0rj.amplifyapp.com/",
        "responseType": "code"
    },
}